import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { boxedLayoutBorder } from "../mixins";
import { textStyles } from "../textStyles";

export const Footer: ComponentMultiStyleConfig = {
	parts: ["container", "link", "copyright", "logo"],

	baseStyle: ({ colorMode, desktopBreakpoint }: StyleFunctionProps) => {
		const color = colorMode === "dark" ? "base.black" : "base.white";
		const bg = colorMode === "dark" ? "base.white" : "base.black";

		return {
			container: {
				bg,
				color,
				pt: { base: "grid.gap", [desktopBreakpoint]: 16 },
				pb: { base: "grid.gap", [desktopBreakpoint]: 8 },
				...boxedLayoutBorder(bg),
			},
			logo: {
				w: "100%",
				maxW: 250,
				height: "auto",
				mb: "grid.gap",
			},
			heading: {
				mb: 10,
				...textStyles.h6,
			},
			link: {
				...textStyles.tertiary,
				textDecoration: "none",
				mb: "2em",
			},
			copyright: {
				...textStyles.small,
				fontSize: {
					base: `${12 / (0.85 * 16)}rem`,
					md: "0.75rem",
				},
				color: colorMode === "dark" ? "base.darkGray" : "base.gray",
				pt: 8,
			},
		};
	},
	variants: {
		landingPage: ({ desktopBreakpoint }) => ({
			container: {
				// Leaving space for the floating button
				pb: { base: 18, sm: 14, [desktopBreakpoint]: 8 },
			},
		}),
	},
};
