import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { convertBpToMediaQuery } from "../../../utils/misc";
import { textStyles } from "../../textStyles";
import { hero } from "./hero";
import { regular } from "./regular";

const counterName = "teaser-list-counter";

export const Teaser: ComponentMultiStyleConfig = {
	parts: [
		"body",
		"category",
		"container",
		"containerLink",
		"image",
		"imageWrapper",
		"inner",
		"list",
		"listitem",
		"newArticleMarker",
		"teaserText",
		"title",
		"wkn",
		"placeholder",
		"placeholderLogo",
		"date",
		"buttonLink",
	],

	baseStyle: () => ({
		/** keep this simple and work with variants */

		container: {
			display: "block",
			w: "100%",

			"&.mmg-teaser-highlighted": {
				bg: "brand.dark",
				color: "base.white",
			},
		},

		containerLink: {
			display: "block",
			width: "100%",
		},

		inner: {
			display: "block",
			width: "100%",
		},

		title: {
			...textStyles.h4,
			_hover: {
				textDecoration: "underline",
			},
		},

		category: {
			fontSize: "sm",
			lineHeight: 1.5,
			fontWeight: "bold",
			letterSpacing: "0.04em",
			color: "brand.dark",
			textTransform: "uppercase",
			pt: 4,
			pb: 3,
			".mmg-teaser-highlighted &": {
				color: "rgba(255, 255, 255, 0.8)",
			},
		},

		teaserText: {
			...textStyles.teaserText,
			".mmg-teaser-highlighted &": {
				color: "base.white",
			},
		},

		newArticleMarker: {
			".mmg-teaser-highlighted &": {
				color: "base.white",
			},
		},

		imageWrapper: {
			position: "relative",
		},

		image: {
			position: "relative",
			width: "100%",
			height: "100%",
		},

		wkn: {
			...textStyles.small,
			py: 2,
			position: "absolute",
			bottom: 0,
			right: 4,
			transform: "translateY(50%)",
			bg: "base.mediumLightGray",
			display: "inline-block",
			fontSize: "xs",
			color: "brand.darker",
			fontWeight: "bold",
			px: 4,
		},

		date: {
			display: "block",
			color: "base.mediumDarkGray",
			...textStyles.tertiary,

			".mmg-teaser-highlighted &": {
				color: "base.white",
			},
		},

		/** special styles */

		list: {
			display: "block",
			w: "100%",
			listStyleType: "none",
			counterReset: counterName,
		},

		listitem: {
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "flex-start",
			counterIncrement: counterName,
			_before: {
				content: `counter(${counterName})`,
				display: "inline-block",
				fontSize: "2xl",
				lineHeight: 1,
				color: "base.darkGray",
				fontFamily: "serif",
				fontWeight: 500,
				pr: 6,
				py: 4,
			},
			_notLast: {
				article: {
					borderBottom: "1px solid",
					borderBottomColor: "base.gray",
				},
			},
			"&.mmg-teaser-highlighted": {
				mt: "-1px", // eats the above items bottom border
				bg: "brand.dark",
				_before: {
					color: "base.white",
				},
				article: {
					borderBottomColor: "transparent",
				},
			},
			article: {
				py: 4,
				flex: 1,
			},
		},

		placeholder: {
			bg: "base.gray",
		},
	}),

	variants: {
		list: {
			title: {
				...textStyles.h4,
				fontWeight: "bold",
			},
		},
		hero,
		regular,
		outlined: () => ({
			body: {
				p: 8,
			},
			category: {
				pt: 0,
				pb: 2,
			},
			title: {
				...textStyles.h3,
				mb: 0,
			},
			teaserText: {
				pt: 3,
				mb: 4,
			},
			container: {
				border: "1px solid",
				borderColor: "base.gray",
			},
			wkn: {
				bottom: "100%",
			},
			date: {
				paddingBottom: 3,
			},
		}),
		"text-only": ({
			desktopBreakpoint: bp,
			theme,
		}: StyleFunctionProps) => ({
			container: {
				"&.mmg-teaser-highlighted": {
					boxShadow: `0 0 0 0.5rem var(--chakra-colors-brand-dark)`,
				},
			},
			category: {
				pt: 0,
				pb: 2,
			},
			teaserText: {
				pt: 2,
				display: { base: "none", [bp]: "-webkit-box" },
			},
			newArticleMarker: {
				pt: 3,
				display: { base: "none", [bp]: "flex" },
			},
			title: {
				[convertBpToMediaQuery(bp, theme)]: {
					...textStyles.h3,
				},
			},
			date: {
				paddingTop: 3,
				paddingBottom: 1,
			},
		}),
		"single-eyecatcher": () => ({
			container: {
				backgroundColor: "base.mediumRed",
				color: "base.white",
			},
			inner: {
				display: "grid",
				gridTemplateColumns: { lg: "5rem 4fr 1.5fr" },
				paddingX: { base: 6, lg: 10 },
				paddingY: { base: 8, lg: 10 },
			},
			category: {
				color: "base.white",
				paddingTop: { base: 6, lg: 0 },
				paddingBottom: 3,
			},
			title: {
				...textStyles.h3,
			},
			teaserText: {
				color: "base.white",
				paddingRight: { base: 0, lg: 24 },
				paddingTop: { base: 6, lg: 4 },
				...textStyles.tertiary,
			},
			buttonLink: {
				alignSelf: "end",
				paddingTop: { base: 8, lg: 0 },

				"& button": {
					minHeight: 10,
				},
			},
		}),
		overview: ({ desktopBreakpoint: bp }: StyleFunctionProps) => ({
			container: {
				py: 0,
				pb: 4,
			},
			category: {
				color: "base.red",
				pt: 0,
			},
			title: {
				...textStyles.h3,
			},
		}),
	},
};
