import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { fullBleed, widgetBorderStyle } from "../mixins";
import { textStyles } from "../textStyles";

const inPageStyles = {
	newsletterWrapper: {
		bg: "base.mediumLightGray",
		padding: { base: 4, lg: 8, xl: 14 },
		border: "none",
		borderRadius: "none",
	},
	title: {
		...textStyles["h1-light"],
	},
};

export const NewsletterForm: ComponentMultiStyleConfig = {
	parts: [
		"container",
		"buttonWrapper",
		"description",
		"disclaimer",
		"newsletterWrapper",
		"newsletterContentWrapper",
		"title",
		"modalTitle",
		"modalDescription",
		"modalDisclaimer",
	],
	baseStyle: {
		newsletterWrapper: {
			backgroundColor: "base.white",
			...widgetBorderStyle,
			p: 7,
		},
		newsletterContentWrapper: {
			"& .newsletter-description": {
				pt: 3,
			},
		},
		description: {
			...textStyles.secondary,
		},
		disclaimer: {
			...textStyles.small,
		},
	},
	variants: {
		wall: {
			newsletterWrapper: {
				p: 0,
				border: "none",
				borderRadius: "none",
			},
			title: {
				textAlign: "left",
			},
			description: {
				textAlign: "left",
			},
		},
		article: {
			...inPageStyles,
		},
		landingPage: {
			...inPageStyles,
		},
		briefingSection: {
			title: {
				...textStyles["h1-light"],
			},

			newsletterWrapper: {
				py: 0,
				px: 0,
				border: "none",
				borderRadius: "none",
			},
		},
		eyeCatcher: {
			newsletterWrapper: {
				...fullBleed,
				backgroundColor: "base.lightGray",
				border: "none",
				borderRadius: "none",
				paddingLeft: { lg: 0 },
				paddingRight: { lg: 0 },
				paddingBottom: { lg: 10 },
			},
			title: {
				position: "relative",
				top: -7,
				borderTop: "5px solid",
				borderColor: "brand.primary",
				fontWeight: "unset",
				paddingTop: 3,
				width: {
					base: 32,
					lg: "100%",
				},
			},
			disclaimer: {
				margin: "0 auto",
				width: "100%",
				pb: { base: 4, lg: 0 },
			},
			buttonWrapper: {
				width: { base: "75%", lg: "unset" },
				my: 4,
				mx: "auto",
				m: { lg: "unset" },
			},
		},
	},
};
