import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const ContentHeaderQuickInfo: ComponentMultiStyleConfig = {
	parts: ["container"],
	baseStyle: {
		container: {
			display: "flex",
			flexDirection: { base: "column", md: "row" },
			width: { base: "auto", md: "max-content" },
		},
	},
	variants: {
		landingPage: {
			container: {
				flexDirection: "row",
				flexWrap: "wrap",
				width: "auto",
				alignItems: "center",
			},
		},
	},
};
