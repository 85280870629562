import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";

export const Tag = {
	parts: ["wrapper", "tag"],
	baseStyle: ({ tagColor }: StyleFunctionProps) => ({
		wrapper: {
			display: "inline-block",
			paddingRight: 3,
			paddingBottom: 2,
		},
		tag: {
			...textStyles.small,
			paddingX: 4,
			paddingY: 2,
			borderRadius: "base",
			border: "1px solid",
			borderColor: tagColor ?? "base.darkGray",
			backgroundColor: tagColor ?? "white",
		},
	}),
};
