import { Dict } from "@chakra-ui/utils";
import { mjTheme } from "./theme/brandThemes/marketJournalTheme";
import { mwTheme } from "./theme/brandThemes/marketWorldTheme";
import type { TeaserVariant } from "./components/teasers/utils";
import { SiteLocale } from "./__generated__/types/graphql-codegen";
import type { BreakpointKeys } from "./utils/types";
import { mbTheme } from "./theme/brandThemes/marketBriefTheme";
import { CmsManifestBrandSlug } from "./__generated__/cms-manifest-types";
import { mtTheme } from "./theme/brandThemes/markettimesTheme";

export type BrandSlug = CmsManifestBrandSlug;

export type TeaserSectionType =
	| "SectionHomeTeaserHeroRecord"
	| "SectionHomeTeaserThreeColumnsTradingviewRecord"
	| "SectionHomeTeaserQuartetRecord"
	| "SectionHomeTeaserSingleRecord"
	| "SectionHomeTeaserTwoByTwoRecord";

export const teasersPerTeaserLayout: Record<TeaserSectionType, number> = {
	SectionHomeTeaserHeroRecord: 1,
	SectionHomeTeaserThreeColumnsTradingviewRecord: 4,
	SectionHomeTeaserQuartetRecord: 4,
	SectionHomeTeaserSingleRecord: 1,
	SectionHomeTeaserTwoByTwoRecord: 4,
};

export type Brand = {
	activeCampaignTag: string;
	articleHeroAspectRatio: number;
	cypressTestArticleSlug?: string;
	cypressTestLandingPageSlug?: string;
	fullLocale: string;
	gtmId?: string;
	hasSearch: boolean;
	homeHighlightIndices: [number, number, number];
	hostSearchWord: string;
	isBoxedLayout: boolean;
	locale: SiteLocale;
	lpHeroAspectRatio: number;
	showRelevantArticles: boolean;
	slug: BrandSlug;
	cookiebotId: string;
};

type SiteConfig = {
	defaultLocale: SiteLocale;
	defaultBrandSlug: BrandSlug;
	brands: Array<Brand>;
};

export const defaultArticleHeroAspectRatio = 2 / 1;
export const defaultLpHeroAspectRatio = 2 / 1;

export const siteConfig: SiteConfig = {
	defaultLocale: SiteLocale.de,
	defaultBrandSlug: "marketbrief.de",
	brands: [
		{
			activeCampaignTag: "44",
			articleHeroAspectRatio: defaultArticleHeroAspectRatio,
			cypressTestArticleSlug:
				"compatible-leading-edge-core-card-extensible-brand",
			cypressTestLandingPageSlug: "mcf-energy-ltd",
			fullLocale: "de_DE",
			gtmId: "GTM-N8CC4MN",
			hasSearch: true,
			homeHighlightIndices: [3, 5, 13],
			hostSearchWord: "marketworld",
			isBoxedLayout: false,
			locale: SiteLocale.de,
			lpHeroAspectRatio: 3 / 1,
			showRelevantArticles: false,
			slug: "marketworld.de",
			cookiebotId: "4fd9969b-db69-4a04-b88e-b7ef72033952",
		},
		{
			activeCampaignTag: "45",
			articleHeroAspectRatio: 3 / 1,
			cypressTestArticleSlug:
				"borsengenehmigung-fur-deutsche-erdgasakquisition-mcf-energy-wird-zu-deutschlands",
			cypressTestLandingPageSlug: "der-gruene-treibstoff-fuer-ihr-depot",
			fullLocale: "de_DE",
			gtmId: "GTM-MSL8KD9",
			hasSearch: false,
			homeHighlightIndices: [3, 5, 13],
			hostSearchWord: "marketbrief",
			isBoxedLayout: true,
			locale: SiteLocale.de,
			lpHeroAspectRatio: 3 / 1,
			showRelevantArticles: true,
			slug: "marketbrief.de",
			cookiebotId: "4fd9969b-db69-4a04-b88e-b7ef72033952",
		},
		{
			activeCampaignTag: "49",
			articleHeroAspectRatio: 2 / 1,
			cypressTestArticleSlug: "mt-test-article--draft",
			cypressTestLandingPageSlug: "mt-test-lp",
			fullLocale: "de_DE",
			gtmId: "GTM-P94484B",
			hasSearch: false,
			homeHighlightIndices: [3, 5, 13],
			hostSearchWord: "markettimes",
			isBoxedLayout: true,
			locale: SiteLocale.de,
			lpHeroAspectRatio: 2 / 1,
			showRelevantArticles: true,
			slug: "markettimes.de",
			cookiebotId: "4fd9969b-db69-4a04-b88e-b7ef72033952",
		},
		{
			activeCampaignTag: "46",
			articleHeroAspectRatio: defaultArticleHeroAspectRatio,
			cypressTestArticleSlug: undefined, // TODO
			cypressTestLandingPageSlug: undefined, // TODO
			fullLocale: "de_DE",
			hasSearch: false,
			homeHighlightIndices: [1, 5, 10],
			hostSearchWord: "marketjournal",
			isBoxedLayout: false,
			locale: SiteLocale.de,
			lpHeroAspectRatio: defaultLpHeroAspectRatio,
			showRelevantArticles: false,
			slug: "marketjournal.de",
			cookiebotId: "4fd9969b-db69-4a04-b88e-b7ef72033952",
		},
	],
};

export const getBrandConfigBySlug = (slug: BrandSlug) => {
	return siteConfig.brands.find((brand) => brand.slug === slug);
};

export const themeDict: Record<BrandSlug, Array<Dict>> = {
	"marketworld.de": [mwTheme],
	"marketjournal.de": [mjTheme],
	"marketbrief.de": [mbTheme],
	"markettimes.de": [mbTheme, mtTheme],
};

const THREE_BY_TWO = 1.501; // 3 / 2 with a little extra to mitigate render/rounding problems in chrome

export type ImageSetting = {
	width: number;
	fileAspectRatio: number;
	responsiveAspectRatio?: number | Partial<Record<BreakpointKeys, number>>;
};

const noImage = {
	fileAspectRatio: 1,
	width: 0,
};

export const articleTeaserImageSettings: Record<TeaserVariant, ImageSetting> = {
	hero: {
		fileAspectRatio: 7 / 3,
		responsiveAspectRatio: {
			base: THREE_BY_TWO,
			sm: 7 / 3,
			mdlg: THREE_BY_TWO,
			xl: 7 / 3,
		},
		width: 640,
	},
	outlined: {
		fileAspectRatio: THREE_BY_TWO,
		responsiveAspectRatio: {
			base: 2,
			sm: 2.5,
			mdlg: 2,
			lgxl: THREE_BY_TWO,
		},
		width: 320,
	},
	regular: {
		fileAspectRatio: THREE_BY_TWO,
		responsiveAspectRatio: {
			base: 2,
			sm: 2.5,
			mdlg: 2,
			lgxl: THREE_BY_TWO,
		},
		width: 320,
	},
	list: noImage,
	"text-only": noImage,
	"single-eyecatcher": noImage,
	overview: noImage,
} as const;
