import { ThemeOverride } from "@chakra-ui/react";
import { boxedLayoutBorder } from "../mixins";
import { textStyles } from "../textStyles";
import { largeGap } from "./baseTheme";

export const mbTheme: ThemeOverride = {
	colors: {
		brand: {
			primary: "#0C4EB8",
		},
		base: {
			pageBg: "#F5F5F5",
			contentHeaderBackground: "#EDEFF0",
			advantagesBackground: "#000",
			contentBg: "#fff",
		},

		ui: {
			menuIndicator: "#000000",
		},
	},
	sizes: {
		grid: {},
		container: {
			maxW: "92rem",
		},
	},
	space: {
		container: {
			"inner.large": largeGap,
		},
	},
	styles: {
		global: {
			".desktop-menu-current-date": {
				display: "none",
			},
		},
	},
	components: {
		CompanyQuickInfo: {
			baseStyle: () => ({
				container: {
					...textStyles.secondary,
				},
			}),
		},
		ContentHeader: {
			baseStyle: {
				row: {
					"& .content-header-full-bleed-grid": {
						gridGap: "grid.gap.small",
					},
				},
				rowWrapper: {
					bg: "base.contentBg",
				},
			},
		},
		Disclaimer: {
			baseStyle: {
				container: {
					bg: "transparent",
					marginTop: 0,
				},
				title: {
					textTransform: "uppercase",
				},
			},
		},
		Footer: {
			baseStyle: {
				container: {
					width: "100%",
					maxW: "container.maxW",
					marginX: "auto",
				},
				logo: {
					maxW: 120,
				},
			},
		},
		Header: {
			baseStyle: {
				logoWrapper: {
					height: "100%",
					display: "flex",
					alignItems: "center",
				},
				logo: {
					width: "full",
					top: "5%",
					height: { base: "60%", lg: "100%" },
					maxWidth: "9rem",
					"[data-scrolldir='down'] &": {
						height: "60%",
					},
				},
				header: {
					maxW: "container.maxW",
					marginX: "auto",
					...boxedLayoutBorder(),
				},
				navItems: {
					flexDirection: "row-reverse",
				},
			},
		},
		HomeSections: {
			baseStyle: {
				outterWrapper: {
					py: 0,
					pb: { base: 12, lg: 7 },
				},
			},
		},
		LayeredBorderDivider: {
			baseStyle: {
				divider: {
					display: "block",
				},
			},
		},
		NewsletterForm: {
			variants: {
				article: {
					container: {
						paddingY: { base: 0, lg: 24 },
					},
				},
				home: {
					newsletterWrapper: {
						px: 10,
						py: 8,
						border: "none",
						borderRadius: "none",
					},
				},
			},
		},
		PopularArticles: {
			baseStyle: {
				container: {
					bg: "transparent",
					pt: 0,
					pb: 8,
				},
				title: {
					mb: 16,
					textTransform: "uppercase",
					...textStyles.h3,
				},
			},
		},
		RiskNotice: {
			baseStyle: {
				container: {
					color: "inherit",
					fontWeight: "normal",
					fontSize: "inherit",
				},
			},
		},
		Teaser: {
			variants: {
				overview: () => ({
					category: {
						color: "base.darkGray",
					},
					title: {
						pb: 4,
					},
				}),
			},
		},
	},
};
