import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const Carousel: ComponentMultiStyleConfig = {
	parts: ["container", "content", "nav"],

	baseStyle: () => {
		return {
			container: {
				position: "relative",
			},
			content: {
				alignSelf: "center",
			},
			nav: {
				position: "absolute",
				bg: "whiteAlpha.500",
				top: "50%",
				transform: "translateY(-50%)",
				zIndex: 1,

				"&[data-carousel-next]": {
					left: "auto",
					right: 0,
				},

				_focus: {
					boxShadow: "none",
					bg: "white", // if you change this, make sure to change the test value in the marketJournalTheme, otherwise tests will fail
				},
			},
		};
	},
};
