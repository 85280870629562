import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const ExternalLinksSection: ComponentMultiStyleConfig = {
	parts: ["container", "image", "grid", "title"],
	baseStyle: {
		container: {
			marginY: 20,
		},
		grid: {
			flex: "1",
			display: "grid",
			gridTemplateColumns:
				"repeat(auto-fit, minmax(var(--chakra-sizes-32), min-content))",
			columnGap:
				"clamp(var(--chakra-sizes-8),14vw,var(--chakra-sizes-56))",
			rowGap: { base: 10, md: 20 },
			justifyContent: "center",
			paddingY: 9,
		},
		image: {
			margin: "0 auto",
			position: "relative",
			height: "100%",
		},
		title: {
			...textStyles.primarySans,
			fontWeight: "600",
		},
		link: {
			"& a": {
				height: "100%",
				minHeight: 12,
				maxHeight: 36,
			},
		},
	},
};
