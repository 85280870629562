import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const MaintenancePage: ComponentMultiStyleConfig = {
	parts: ["container", "logo", "title", "subtitle"],
	baseStyle: () => ({
		container: {
			minHeight: "100vh",
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "center",
			alignItems: "center",
		},
		logo: {
			minW: 16,
			h: "auto",
			mr: 6,
			pr: 6,
			borderRight: "2px solid",
			borderRightColor: "base.gray",
		},
		title: {
			...textStyles.h4,
		},
		subtitle: {
			...textStyles.p,
		},
	}),
};
