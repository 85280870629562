import { ComponentMultiStyleConfig } from "@chakra-ui/react";
// import { convertBpToMediaQuery } from "../../utils/misc";
import { fullBleed } from "../mixins";
import { textStyles } from "../textStyles";

export const HighlightedSection: ComponentMultiStyleConfig = {
	parts: ["wrapper", "container", "title", "titleBorder", "buttonWrapper"],
	baseStyle: {
		wrapper: {
			// The one time it makes sense to use margin, because it may be surrounded by Structured Text vertical margin
			marginTop: 10,
		},
		container: {
			paddingBottom: 6,
			marginBottom: 6,
			"*:last-child > &": {
				marginBottom: { base: 0, lg: -8 },
				paddingBottom: 8,
			},
			...fullBleed,
			// Temporarily on hold due to safari bug, which should be fixed with the rgba value below
			// _before: {
			// 	...fullBleed._before,
			// 	bgGradient:
			// 		"linear(to-t, base.lightGray, rgba(255, 255, 255, 0))",
			// },
			// [convertBpToMediaQuery("md")]: {
			// 	_before: {
			// 		bgGradient: "none",
			// 	},
			// },
			// [convertBpToMediaQuery("xl")]: {
			// 	_before: {
			// 		bgGradient:
			// 			"linear(to-t, base.lightGray, rgba(255, 255, 255, 0))",
			// 	},
			// },
		},
		title: {
			display: "inline-block",
			color: "brand.dark",
			paddingTop: 3,
			paddingBottom: {
				base: 6,
				lg: 9,
			},
		},
		titleBorder: {
			width: 48,
			borderTop: "5px solid",
			borderColor: "brand.dark",
		},
		description: {
			...textStyles.primary,
		},
		buttonWrapper: {
			paddingTop: 6,
			width: {
				lg: 48,
			},
		},
	},
};
