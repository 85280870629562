import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const Wall: ComponentMultiStyleConfig = {
	parts: ["overlay", "header", "body", "buttons"],
	baseStyle: ({ theme }) => ({
		wrapper: {
			background: "base.white",
			position: "absolute",
			zIndex: 1,
			top: "50%",
			left: { base: "1rem", md: "50%", xl: "28%" },
			transform: {
				base: "translateY(-50%)",
				md: "translate(-50%, -50%)",
			},
			boxShadow: "soft",
			width: { base: "calc(100% - 2rem)", md: "2xl" },
		},
		header: {
			backgroundColor: "base.black",
			p: 6,
			py: 8,
			display: "flex",
			justifyContent: "center",
		},
		body: {
			margin: "auto",
			paddingX: { base: 5, md: 10 },
			paddingY: { base: 5, md: 10 },
			textAlign: "center",
		},
		buttons: {
			display: "flex",
			flexDirection: { base: "column-reverse", md: "row" },
			justifyContent: "space-between",
			gap: "grid.gap",
		},
	}),
};
