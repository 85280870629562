import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const PressReleaseSection: ComponentMultiStyleConfig = {
	parts: ["container", "title", "grid", "leftColumn", "rightColumn"],
	baseStyle: {
		container: {
			pb: 12,
		},
		grid: {
			display: "grid",
			gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
			gap: "grid.gap",
		},
		leftColumn: {
			gridColumn: { base: "1/-1", xl: "span 7" },
		},
		rightColumn: {
			gridColumn: "9 / -1",
			display: { base: "none", xl: "block" },
		},
	},
};
