import { cssVar } from "@chakra-ui/react";

export const fillParent = {
	width: "100%",
	height: "100%",
	position: "absolute",
	top: 0,
	left: 0,
};

export const fullBleed = {
	position: "relative",
	zIndex: 1,
	_before: {
		content: '""',
		zIndex: -1,
		position: "absolute",
		display: "block",
		top: 0,
		left: "calc(50% - var(--fifty-vw))",
		width: `var(--hundred-vw)`,
		height: "100%",
		backgroundColor: "inherit",
		backgroundImage: "inherit",
		pointerEvents: "none",
	},
	"[data-boxed-layout=true] &": {
		_before: {
			left: {
				base: "calc(50% - var(--fifty-vw))",
				xl: "calc(-1 * var(--chakra-space-grid-gap-large))",
			},
			maxW: {
				base: `100vw`,
				xl: "calc(var(--chakra-sizes-container-maxW) - 2px)",
			},
		},
	},
};

export const getThemeColor = (name: string) => cssVar(`chakra-colors-${name}`);

export const boxedLayoutBorderColor = cssVar(
	"boxed-layout-border-color",
	getThemeColor("base-gray").reference,
);

export const boxedLayoutBorder = (color?: string) => ({
	"[data-boxed-layout=true] &": {
		borderLeftColor: color ?? boxedLayoutBorderColor.reference,
		borderRightColor: color ?? boxedLayoutBorderColor.reference,
		borderLeftStyle: "solid",
		borderRightStyle: "solid",
		borderLeftWidth: {
			base: 0,
			lg: "1px",
		},
		borderRightWidth: {
			base: 0,
			lg: "1px",
		},
	},
});

export const widgetBorderStyle = {
	border: "1px solid",
	borderColor: "base.gray",
	borderRadius: "0.25rem",
};
