import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";

export const CompanyQuickInfo: ComponentMultiStyleConfig = {
	parts: [
		"container",
		"item",
		"value",
		"valueLabel",
		"divider",
		"copyButton",
	],
	baseStyle: ({ desktopBreakpoint: bp }: StyleFunctionProps) => ({
		container: {
			...textStyles.tertiary,
			display: "flex",
			flexFlow: "row wrap",
			alignItems: "center",
		},
		item: {
			display: "block",
			whiteSpace: "pre",
			pr: 6,
			pb: { base: 4, [bp]: 0 },
			_last: {
				pr: 0,
			},
		},
		value: {
			display: "flex",
			alignItems: "center",
		},
		valueLabel: {
			textTransform: "uppercase",
		},
		copyButton: {
			ml: 3,
		},
	}),
	variants: {
		landingPage: {
			container: {
				width: "auto",
				...textStyles.secondary,
			},
			item: {
				justifyContent: "flex-start",
				marginRight: 3,
			},
		},
	},
};
