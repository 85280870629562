import { CSSObject } from "@emotion/react";
import { fromEntries } from "../utils/misc";

const headingBase: CSSObject = {
	fontFamily: "sans",
	fontWeight: 600,
	lineHeight: "1.2",
};

const round = (num: number) => Math.round(num * 1000) / 1000;

// copy px values from Adobe XD and forget, rem is autocalculated here
const xdHelper = (fontSizePx: number, lineHeightPx: number): CSSObject => ({
	fontSize: `${round(fontSizePx / 16)}rem`,
	lineHeight: `${round(lineHeightPx / fontSizePx)}`,
});

const sansHeadingStyles: Record<string, CSSObject> = {
	h1: {
		...headingBase,
		...xdHelper(44, 48),
	},
	h2: {
		...headingBase,
		...xdHelper(34, 40),
	},
	h3: {
		...headingBase,
		...xdHelper(24, 30),
	},
	h4: {
		...headingBase,
		...xdHelper(18, 22),
	},
	h5: {
		...headingBase,
		...xdHelper(16, 20),
	},
	h6: {
		...headingBase,
		...xdHelper(14, 18),
	},
};

const serifHeadingStyles: Record<string, CSSObject> = fromEntries(
	Object.entries(sansHeadingStyles).map(([key, value]) => [
		`${key}-serif`,
		{ ...value, fontFamily: "serif" },
	]),
);

const lightHeadingStyles: Record<string, CSSObject> = fromEntries(
	Object.entries(sansHeadingStyles).map(([key, value]) => [
		`${key}-light`,
		{ ...value, fontWeight: "light" },
	]),
);

export const headingStyles: Record<string, CSSObject> = {
	...sansHeadingStyles,
	...serifHeadingStyles,
	...lightHeadingStyles,
	landingPageDividerHeading: {
		...sansHeadingStyles.h3,
		paddingBottom: 10,
	},
};

export const textStyles: Record<string, CSSObject> = {
	...headingStyles,
	primary: {
		fontFamily: "serif",
		...xdHelper(20, 32),
	},
	primarySans: {
		fontFamily: "sans",
		...xdHelper(20, 32),
	},
	secondary: {
		fontFamily: "sans",
		...xdHelper(18, 26),
	},
	tertiary: {
		...xdHelper(14, 22),
		fontFamily: "sans",
	},
	highlighted: {
		fontFamily: "serif",
		fontStyle: "italic",
		...xdHelper(20, 26),
	},
	newArticleMarker: {
		fontFamily: "sans",
		...xdHelper(14, 18),
	},
	disclaimer: {
		fontFamily: "sans",
		...xdHelper(16, 24),
	},
	report: {
		fontFamily: "sans",
		...xdHelper(20, 32),
	},
	small: {
		fontFamily: "sans",
		...xdHelper(12, 15),
	},
	default: {
		fontFamily: "sans",
		...xdHelper(16, 24),
	},
	fineprint: {
		fontFamily: "sans",
		...xdHelper(10, 13),
	},
	quote: {
		...xdHelper(24, 38),
		fontFamily: "serif",
		fontStyle: "italic",
		color: "base.darkGray",
	},
	teaserText: {
		...xdHelper(14, 22),
		fontFamily: "sans",
		color: "base.mediumDarkGray",
	},
	subline: {
		...xdHelper(20, 32),
		fontFamily: "sans",
		color: "base.darkerGray",
	},
	companyWidget: {
		...xdHelper(16, 24),
		fontFamily: "sans",
	},
	briefingSection: {
		...xdHelper(18, 30),
		fontFamily: "serif",
	},
	argumentsHeading: {
		...xdHelper(28, 38),
		fontFamily: "sans",
		fontWeight: "light",
	},
};
