import { theme } from "@chakra-ui/react";
import {
	StyleFunctionProps,
	SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const variants = theme.components.Button.variants!;

const chakraSolid = variants.solid;
const chakraOutline = variants.outline;
const chakraLink = variants.link;

export const primary: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...chakraSolid(props),
	color: "base.white",
	borderRadius: 4,
	bg: "brand.primary",
	_hover: {
		bg: "brand.secondary",
		_disabled: {
			bg: "brand.secondary",
		},
	},
	_active: {
		bg: "brand.secondary",
		_disabled: {
			bg: "brand.secondary",
		},
		opacity: 0.8,
	},
});

const primaryDark: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...primary(props),
	bg: "brand.secondary",
	_hover: {
		bg: "brand.primary",
	},
});

const black: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...chakraSolid(props),
	color: "base.white",
	borderRadius: 4,
	bg: "base.black",
	_hover: {
		bg: "base.white",
		color: "base.black",
		_disabled: {
			bg: "base.white",
		},
	},
	_active: {
		bg: "base.white",
		color: "base.black",
		_disabled: {
			bg: "base.white",
		},
		opacity: 0.8,
	},
});

export const secondary: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...chakraOutline(props),
	borderRadius: 4,
	borderColor: "brand.primary",
	color: "brand.primary",
	_hover: {
		borderColor: "brand.secondary",
		color: "brand.secondary",
	},
	_active: {
		bg: "transparent",
		opacity: 0.8,
	},
});

const secondaryRed: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...secondary(props),
	fontWeight: 400,
	color: "base.red",
	borderColor: "base.red",
	bg: "base.lighterRed",
	_hover: {
		bg: "base.white",
	},
	_focus: {
		boxShadow: "outline.red",
	},
});

const secondaryBlack: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...secondary(props),
	fontWeight: 400,
	color: "base.black",
	borderColor: "base.black",
	bg: "base.lightGray",
	_hover: {
		bg: "base.white",
	},
	_focus: {
		boxShadow: "outline.red",
	},
});

const secondaryWhite: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...secondary(props),
	fontWeight: 400,
	color: "base.white",
	borderColor: "base.white",
	bg: "transparent",
	_hover: {
		bg: "base.darkerGray",
	},
});

const link: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...chakraLink(props),
	...textStyles.secondary,
	minHeight: 10,
	color: "base.darkGray",
	p: "0 1rem",
	fontWeight: "normal",
	textDecoration: "underline",
	_hover: {
		color: "brand.primary",
	},
	_focus: {
		color: "brand.primary",
	},
});

const inlineLink: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...link(props),
	minHeight: 0,
	height: "auto ",
	color: "base.white",
	p: 0,
	borderRadius: 0,
	_hover: {
		color: "brand.primary",
		textDecoration: "underline",
	},
	_focus: {
		color: "white",
	},
});

const overlayClose: SystemStyleFunction = (props: StyleFunctionProps) => ({
	...secondaryBlack(props),
	backgroundColor: "base.white",
	fontWeight: "bold",
	minHeight: 14,
});

export const Button = {
	defaultProps: {
		variant: "primary",
	},
	variants: {
		primary,
		"primary-dark": primaryDark,
		secondary,
		link,
		"inline-link": inlineLink,
		ghost: {
			borderRadius: 0,
		},
		"secondary-red": secondaryRed,
		"secondary-black": secondaryBlack,
		"secondary-white": secondaryWhite,
		"secondary-red-dark": secondaryRed,
		black,
		bookmark: {
			minW: 6,
			height: 6,
			color: "base.red",
			transition: "background 0.1s ease-in",
			"&[data-bookmarked=true]": {
				svg: {
					path: {
						fill: "currentColor",
					},
				},
			},
			_hover: {
				bg: "base.lightRed",
				_disabled: {
					bg: "base.lightRed",
				},
				_checked: {
					bg: "base.lightRed",
				},
			},
			_active: {
				bg: "base.lightRed",
				_disabled: {
					bg: "base.lightRed",
				},
				opacity: 0.8,
			},
			_focus: {
				boxShadow: "focusNoPadding",
			},
		},
		floating: {
			minHeight: 14,
			position: "fixed",
			zIndex: 2,

			// wasn't getting any results from env's second "fallback" argument
			right: "calc(1rem + env(safe-area-inset-right))",
			bottom: "calc(1rem + env(safe-area-inset-bottom))",

			color: "base.white",
			backgroundColor: "base.black",
			borderRadius: 0,

			display: {
				// default for chakra button
				base: "inline-flex",
				lg: "none",
			},
		},
		"overlay-close": overlayClose,
	},
};
