import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { convertBpToMediaQuery } from "../../../utils/misc";
import { bannerHeight, desktopMenuHeight, mobileMenuHeight } from "../header";

export const LandingPageContentSection: ComponentMultiStyleConfig = {
	parts: ["title", "grid", "leftColumn", "rightColumn"],
	baseStyle: ({ theme }) => ({
		grid: {
			display: "grid",
			gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
			gap: "grid.gap",
		},
		leftColumn: {
			gridColumn: { base: "1/-1", xl: "span 7" },

			// This probably doesn't cover all our bases but :first-child is not allowed
			// TODO: find a workaround:
			"& h2:first-of-type": {
				marginTop: 0,
			},
		},
		rightColumn: {
			gridColumn: "9 / -1",
			display: { base: "none", xl: "block" },
			height: "max-content",

			[convertBpToMediaQuery("lg", theme)]: {
				position: "sticky",
				pt: 4,
				mt: -4,
				top: {
					base: mobileMenuHeight,
					lg: desktopMenuHeight,
				},
				"[data-has-top-banner=true] &": {
					top: {
						base: mobileMenuHeight + bannerHeight,
						lg: desktopMenuHeight + bannerHeight,
					},
				},
			},
		},
		title: {
			paddingBottom: 14,
		},
	}),
};
