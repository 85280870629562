import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const NewsletterParts: ComponentMultiStyleConfig = {
	parts: [
		"modalButtonWrapper",
		"modalContent",
		"modalDescription",
		"modalDisclaimer",
		"modalTitle",
		"newsletterButtonLink",
		"newsletterPageCircle",
		"newsletterPageContent",
		"newsletterPageText",
		"newsletterPageWrapper",
	],
	baseStyle: {
		modalButtonWrapper: {
			flex: "auto",
			flexDirection: { base: "column", lg: "row" },
			gap: 4,
			width: "80%",
		},
		modalContent: {
			spacing: "7",
			textAlign: "center",
			width: { md: "min-content" },
			margin: "0 auto",
		},
		modalDescription: {
			...textStyles.secondary,
		},
		modalDisclaimer: {
			...textStyles.tertiary,
			color: "base.darkerGray",
			paddingY: 12,
			textAlign: "center",
		},
		modalTitle: {
			...textStyles.h3,
			whiteSpace: { base: "initial", md: "nowrap" },
		},
		newsletterPageCircle: { width: 16, height: 16, paddingTop: 5 },
		newsletterPageContent: {
			paddingTop: 12,
			paddingBottom: 7,
			textAlign: "center",
		},
		newsletterButtonLink: {
			textDecoration: "none",
			_hover: {
				textDecoration: "none",
			},
		},
		newsletterPageText: { width: "80%", margin: "0 auto", paddingTop: 8 },
		newsletterPageWrapper: {
			alignItems: "center",
			flexDirection: "column",
			paddingBottom: 44,
		},
	},
};
