import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { boxedLayoutBorder, fillParent } from "../../mixins";
import { LandingPage as LandingPageType } from "../../../utils/types";
import { headingStyles } from "../../textStyles";

type SectionTypeName = NonNullable<
	LandingPageType["sectionsLink"]
>["sections"][number]["__typename"];
type Parts =
	| SectionTypeName
	| "outterWrapper"
	| "headerImage"
	| "imageHeroContent"
	| "imageHeroSubline"
	| "imageHeroTitle"
	| "imageHeroWrapper";

const parts: Array<Parts> = [
	"outterWrapper",
	"headerImage",
	"imageHeroWrapper",
	"imageHeroContent",
	"imageHeroSubline",
	"imageHeroTitle",
	"SectionLandingPageContentRecord",
];

export const LandingPage: ComponentMultiStyleConfig = {
	parts,
	baseStyle: {
		outerWrapper: {
			pt: 8,
			pb: 0,
			...boxedLayoutBorder(),
		},
	},
	variants: {
		"markettimes-de": ({ theme }) => ({
			headerImage: {
				height: { base: "60vh", lg: "46rem" },
			},
			imageHeroWrapper: {
				position: "relative",
				maxW: "container.maxW",
				margin: "auto",
			},
			imageHeroContent: {
				...fillParent,
				background: `transparent linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%) 0% 0% no-repeat padding-box`,
				display: "flex",
				flexFlow: "column nowrap",
				gap: 4,
				alignItems: { base: "flex-start", lg: "center" },
				justifyContent: "flex-end",
				padding: { base: 8 },

				color: "white",
				"> *": {
					color: "white",
				},
			},
			imageHeroTitle: {
				...headingStyles.h1,
				fontSize: { base: "1.8rem", md: "2.75rem" },
				textAlign: { base: "left", lg: "center" },
				hyphens: { base: "auto", lg: "initial" },
				maxW: "100%",
			},
			imageHeroSubline: {
				textStyle: "primarySans",
				fontWeight: "bold",
				textAlign: { base: "left", lg: "center" },
				py: 4,
			},
		}),
	},
};
