import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { convertBpToMediaQuery } from "../../utils/misc";
import { textStyles } from "../textStyles";

export const ContentTitle: ComponentMultiStyleConfig = {
	parts: ["container", "headline", "subline"],
	baseStyle: ({ desktopBreakpoint: bp, theme }: StyleFunctionProps) => {
		return {
			container: {
				display: "flex",
				width: "100%",
				justifyContent: "space-between",
				alignItems: "flex-start",
				flexWrap: "nowrap",
			},
			headline: {
				mb: { base: "grid.gap.small", lg: 6 },
				...textStyles.h3,
				[convertBpToMediaQuery(bp, theme)]: {
					...textStyles.h1,
				},
			},
			subline: {
				...textStyles.subline,
				color: "base.black",
			},
		};
	},
};
