import { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const Assessment: ComponentSingleStyleConfig = {
	baseStyle: ({ desktopBreakpoint: bp }) => ({
		display: "flex",
		justifyContent: "center",
		textAlign: "left",
		paddingY: 5,
		"> *": {
			paddingX: 0.5,
			flex: "1 0 50%",
			whiteSpace: "nowrap",
			_first: {
				textAlign: "right",
			},
			_last: {
				fontWeight: "bold",
			},
		},
		"&.full-width": {
			marginX: { base: -2, [bp]: -4 },
			justifyContent: "space-between",
			"> *": {
				paddingX: 4,
				flex: "0 1 auto",
			},
		},
	}),
	variants: {
		single: {
			...textStyles.secondary,
			py: 2,
			px: 10,
			borderRadius: "sm",
			"&.full-width": {
				mx: "auto",
			},
		},
	},
};
