import { ThemeOverride } from "@chakra-ui/react";

export const mwTheme: ThemeOverride = {
	components: {
		LandingPage: {
			baseStyle: {
				headerImage: {
					"> *": {
						borderRadius: "0.25rem",
					},
				},
			},
		},
		LayeredBorderDivider: {
			baseStyle: () => {
				return {
					divider: {
						"[data-page-type='landing-page'] &": {
							display: "block",
						},
					},
				};
			},
		},
		KeyPoints: {
			baseStyle: {
				container: {
					"[data-page-type='landing-page'] &": {
						borderRadius: "0.25rem",
					},
				},
			},
		},
		Arguments: {
			baseStyle: {
				container: {
					borderRadius: "0.25rem",
				},
			},
		},
	},
};
