import { ThemeOverride } from "@chakra-ui/react";
import { boxedLayoutBorderColor, fullBleed, getThemeColor } from "../mixins";

export const mtTheme: ThemeOverride = {
	colors: {
		brand: {
			primary: "#000000",
		},
		base: {
			advantagesBackground: "#EDEFF0",
		},
	},
	components: {
		Header: {
			baseStyle: {
				logo: {
					maxWidth: "12rem",
				},
			},
		},
		StaticPage: {
			baseStyle: {
				container: {
					bg: "base.white",
				},
			},
		},
		NotFoundPage: {
			baseStyle: {
				container: {
					bg: "base.white",
				},
			},
		},
		Advantages: {
			variants: {
				"markettimes-de": {
					container: {
						py: { base: 8, lg: 12 },
						...fullBleed,
					},
					item: {
						display: { base: "flex", lg: "block" },
						svg: {
							position: "relative",
							width: 12,
							height: 12,
							top: { base: 4, lg: "auto" },
						},
					},
					itemTitle: {
						ps: { base: 6, lg: 0 },
						pt: { base: 0, lg: 8 },
					},
					itemContent: {
						ps: { base: 18, lg: 0 },
					},
				},
			},
		},
	},
	styles: {
		global: {
			":root": {
				[boxedLayoutBorderColor.variable]:
					getThemeColor("base-pageBg").reference,
			},
		},
	},
};
