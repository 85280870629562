import { visuallyHiddenStyle } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const CurrentDate = {
	parts: ["container", "day", "date", "divider"],
	baseStyle: {
		container: {
			...textStyles.small,
			color: "base.darkerGray",
		},
		divider: {
			px: 2,
			display: "inline-block",
		},
		date: {},
		day: {},
	},
	variants: {
		black: {
			container: {
				color: "base.black",
			},
		},
		short: {
			divider: { ...visuallyHiddenStyle },
			day: { ...visuallyHiddenStyle },
		},
	},
};
