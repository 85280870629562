import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const GridNewsletterForm: ComponentMultiStyleConfig = {
	parts: ["gridWrapper", "left", "main", "right"],
	baseStyle: {
		// Only want to use the Grid Layout on lg and above
		gridWrapper: {
			display: { lg: "grid" },
			gridTemplateColumns: { lg: "repeat(12, 1fr)" },
			gap: { lg: "grid.gap" },
		},
		left: {
			display: { base: "inline-block", lg: "grid" },
			gridColumnStart: { lg: 1 },
			gridColumnEnd: { lg: 3 },
		},
		main: {
			pt: { lg: 5 },
			gridColumnStart: { lg: 3 },
			gridColumnEnd: { lg: 10 },
		},
		right: {
			pt: { lg: 5 },
			gridColumnStart: { lg: 10 },
			gridColumnEnd: { lg: 13 },
		},
	},
};
