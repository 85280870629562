import { ComponentSingleStyleConfig, CSSObject } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";
import { primary, secondary } from "./buttons";
import { desktopBreakpoint as headerDesktopBreakpoint } from "./header";

const menuLinkCommon = () => ({
	...textStyles.default,
	fontWeight: { base: "bold", [headerDesktopBreakpoint]: "normal" },
	letterSpacing: "0.03125rem",
	py: 4,
	display: "inline-block",
	textDecoration: "none",
	width: "100%",
	transition: "box-shadow 400ms",
	em: {
		textTransform: "none",
		fontWeight: "normal",
	},
	"&.is-active": {
		fontWeight: "bold",
		em: {
			textTransform: "none",
			fontWeight: "bold",
		},
		boxShadow: "menuIndicator",
	},
	"footer &": {
		fontWeight: "normal",
	},
	".subscribe &": {
		backgroundColor: "base.black",
		color: "base.white",
		px: 3,
		fontWeight: "bold",
		mt: { base: 4, lg: 0 },
		borderRadius: { base: "md", lg: "none" },
		_hover: {
			color: "base.gray",
			boxShadow: "menuIndicator.focus.gray",
		},
	},
});

export const Link: ComponentSingleStyleConfig = {
	baseStyle: {
		textDecoration: "underline",
		_hover: {
			color: "brand.primary",
		},

		_focus: {
			boxShadow: "focus",
			outline: "none",
		},
	},
	variants: {
		"no-underline": {
			textDecoration: "none",
			_hover: {
				textDecoration: "none",
				color: "inherit",
			},
		},
		breadcrumb: {
			...textStyles.tertiary,
			textDecoration: "none",
			textTransform: "uppercase",
			p: 2,
		},
		companyWidget: {
			textDecoration: "none",
			fontWeight: "bold",
		},
		"structured-text-content": ({ colorMode }: StyleFunctionProps) => ({
			color: "brand.primary",
			_hover: {
				color: colorMode === "dark" ? "base.white" : "base.black",
			},
		}),
		"menu-link-desktop": () => {
			const common = menuLinkCommon();

			return {
				...common,
				lineHeight: 1,
				_hover: {
					boxShadow: "menuIndicator.focus",
					textDecoration: "none",
				},
				_active: {
					boxShadow: "menuIndicator.focus",
				},
				_focus: {
					boxShadow: "menuIndicator.focus",
				},

				"&.is-active": {
					...common["&.is-active"],
					_focus: {
						boxShadow: "menuIndicator.focus",
					},
				},
			};
		},
		"menu-link-mobile": () => {
			const common = menuLinkCommon();

			return {
				...menuLinkCommon(),
				...textStyles.secondary,
				_focus: {
					boxShadow: "focus",
				},
				"&.is-active": {
					...common["&.is-active"],
					boxShadow: "none",
					color: "brand.primary",
					_focus: {
						boxShadow: "focus",
					},
				},
			};
		},
		button: (props) => {
			const primaryButton = primary(props);

			return {
				py: 2,
				px: 4,
				textDecoration: "none",
				textAlign: "center",
				display: "inline-block",
				...primaryButton,
				_hover: {
					color: "white",
					textDecoration: "none",
					...(primaryButton as { _hover: CSSObject })._hover,
				},
			};
		},
		"button-secondary": (props) => {
			const secondaryButton = secondary(props);

			return {
				py: 2,
				px: 4,
				textDecoration: "none",
				textAlign: "center",
				display: "inline-block",
				...secondaryButton,
				_hover: {
					...(secondaryButton as { _hover: CSSObject })._hover,
					textDecoration: "none",
					color: "brand.secondary",
				},
			};
		},
	},
};
