import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../../textStyles";

export const regular = ({ desktopBreakpoint }: StyleFunctionProps) => ({
	wkn: {
		display: "block",
	},
	imageWrapper: {
		mb: 6,
	},
	inner: {
		display: "flex",
		alignItems: "stretch",
		flexFlow: "column nowrap",
	},
	body: {
		display: "flex",
		flexFlow: "column nowrap",
		flex: "1",
		".mmg-teaser-highlighted &": {
			px: 4,
			pb: 4,
		},
	},
	category: {
		pt: 0,
		pb: 2,
	},
	title: {
		...textStyles.h3,
		mb: 0,
	},
	teaserText: {
		display: "-webkit-box",
		pt: 3,
		mb: 4,
	},
	newArticleMarker: {
		pb: 4,
		mb: 0,
		mt: "auto",
		".mmg-teaser-highlighted &": {
			position: "relative",
			top: 4,
		},
	},
	date: {
		pt: 3,
	},
});
