import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { convertBpToMediaQuery } from "../../utils/misc";

export const TwoByTwoTextOnlyTeasers: ComponentMultiStyleConfig = {
	parts: ["wrapper", "teaserContainer"],
	baseStyle: ({ desktopBreakpoint: bp, theme }: StyleFunctionProps) => {
		return {
			wrapper: {
				display: "grid",
				gridTemplateColumns: { base: "auto", md: "1fr 1fr" },
				gridTemplateRows: { base: "auto", md: "auto auto" },
				gap: 6,
				width: "100%",
			},
			teaserContainer: {
				display: "flex",
				flexDir: "column",
				justifyContent: "space-between",
				[convertBpToMediaQuery("base", theme)]: {
					_last: {
						"& .divider": {
							display: "none",
						},
					},
				},
				[convertBpToMediaQuery("md", theme)]: {
					"&:last-child, &:nth-last-of-type(2)": {
						"& .divider": {
							display: "none",
						},
					},
				},
			},
		};
	},
};
