import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const Author: ComponentMultiStyleConfig = {
	parts: ["container", "nameWrapper", "title"],
	baseStyle: ({ desktopBreakpoint: bp }) => ({
		container: {
			display: "flex",
			alignItems: "center",
			pb: { base: 3, [bp]: 0 },
		},

		title: {
			...textStyles.tertiary,
			fontWeight: "bold",
			letterSpacing: "0.04em",
			textTransform: "uppercase",
			color: "base.mediumDarkGray",
			display: {
				base: "none",
				[bp]: "block",
			},
		},
		nameWrapper: {
			...textStyles.tertiary,
			fontWeight: { base: "normal", [bp]: "bold" },
			letterSpacing: "0.04em",
		},
		name: {
			textDecoration: { base: "underline", [bp]: "none" },
		},
	}),
};
