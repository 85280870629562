export const Switch = {
	defaultProps: {
		size: "sm",
	},
	baseStyle: {
		container: {
			"--switch-track-width": "2rem",
		},
		track: {
			bg: "base.gray",
			_checked: {
				bg: "base.gray",
			},
		},
	},
};
