import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { desktopMenuHeight, mobileMenuHeight } from "./brandThemes/header";
import { fillParent } from "./mixins";

export const globalStyles = ({ colorMode }: StyleFunctionProps) => ({
	html: {
		// md is 48em so up to 768px we use 0.85rem as a base font-size
		fontSize: { base: "sm", md: "md" },
		scrollBehavior: "smooth",
		scrollPaddingTop: {
			base: mobileMenuHeight + 4,
			md: desktopMenuHeight + 4,
		},
	},
	body: {
		bg: colorMode === "dark" ? "base.pageBgDark" : "base.pageBg",
		color: "base.black",
	},
	".inner-content-section": {
		bg: colorMode === "dark" ? "base.contentBgDark" : "base.contentBg",
	},
	".walled-section": {
		position: "relative",

		maxHeight: "66vh",
		overflow: "hidden",

		_after: {
			pointerEvents: "none",
			content: '""',
			background:
				"linear-gradient(to bottom, transparent, var(--chakra-colors-base-contentBg))",
			...fillParent,
		},
	},
});
