import { ComponentSingleStyleConfig } from "@chakra-ui/react";

export const CMSAvatar: ComponentSingleStyleConfig = {
	baseStyle: {
		mr: 6,
		display: "block",
		width: "100%",
		maxWidth: 20,
		borderRadius: "full",
		overflow: "hidden",
	},
	variants: {
		small: {
			mr: 8,
			display: {
				base: "none",
				lg: "block",
			},
			maxWidth: 16,
		},
	},
};
