import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const CompanyOverlay: ComponentMultiStyleConfig = {
	parts: ["container", "mobileWrapper", "mobileDrawer"],
	baseStyle: () => {
		const wrapperBase = {
			display: "flex",
			justifyContent: "space-between",
			height: "100%",
			alignItems: "center",
			width: "100%",
		};

		return {
			mobileWrapper: {
				...wrapperBase,
				alignItems: "center",
			},
			mobileDrawer: {
				height: "100%",
			},
			mobileBody: {
				pt: 14,
				px: "container.inner.small",
			},
		};
	},
};
