import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const Gallery: ComponentMultiStyleConfig = {
	parts: ["container", "singleImage", "lightboxButton"],

	baseStyle: () => {
		return {
			container: {
				py: 8,
			},
			singleImage: {
				position: "relative",
				display: "block",
				w: "100%",
			},
			iconHeading: {
				pb: 6,
			},
			lightboxButton: {
				color: "base.white",
				pointerEvents: "none",
				position: "absolute",
				bottom: "1rem",
				left: "1rem",
				zIndex: 1,
				opacity: 0,
				transition: "opacity 400ms",
				_groupHover: { opacity: 1 },
			},
			item: {
				display: "block",
				w: "100%",
			},
		};
	},
};
