import { keyframes } from "@chakra-ui/react";

export const pulseOpacity = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0.7;
    }
`;

export const pulseScale = keyframes`
    from {
        transformOrigin: 50% 50%;
        transform: scale(1);
    }
    to {
        transform: scale(0.5);
    }
`;
