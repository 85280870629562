import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { convertBpToMediaQuery } from "../../../utils/misc";
import { textStyles } from "../../textStyles";

export const hero = ({ desktopBreakpoint: bp, theme }: StyleFunctionProps) => ({
	inner: {
		display: "flex",
		flexFlow: {
			base: "column-reverse nowrap",
			[bp]: "row nowrap",
		},
		alignItems: "flex-start",
	},
	container: {
		"--highlight-overflow": "2rem",
	},

	body: {
		display: "block",
		pr: { base: 0, [bp]: 20 },
		flex: "1 0",
	},
	title: {
		...textStyles.h2,
		mb: "0.5em",

		[convertBpToMediaQuery(bp, theme)]: {
			...textStyles.h1,
		},
	},
	category: {
		textTransform: "none",
		pt: { base: 4, [bp]: 0 },
	},
	teaserText: {
		display: "block",
		fontSize: "md",
	},
	newArticleMarker: {
		pt: 5,
	},
	imageWrapper: {
		flex: { base: 1, [bp]: "0 1 calc(50% - 2rem)" },
		width: { base: "100%", [bp]: "calc(50% - 2rem)" },
		left: { base: "auto", [bp]: "-2rem" },
	},
	image: {
		boxShadow: {
			base: "none",
			[bp]: "2rem -2rem 0 0 var(--chakra-colors-base-lightGray), 2rem 2rem 0 0 var(--chakra-colors-base-lightGray)",
		},
		"[data-teaser-type] a:focus &": {
			boxShadow: "none !important",
		},
	},
	date: {
		paddingBottom: 4,
	},
});
