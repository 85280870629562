import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { fullBleed } from "../mixins";

export const CrossPromoSection: ComponentMultiStyleConfig = {
	parts: ["container", "logo", "logoWrapper", "popularArticlesHeading"],

	baseStyle: ({ backgroundColor: bg, primaryColor }: StyleFunctionProps) => ({
		container: {
			bg,
			pb: 8,
			...fullBleed,
		},
		logoWrapper: {
			position: "relative",
			_before: {
				content: "''",
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				maxW: {
					base: 120,
					lg: "none",
				},
				borderTop: "5px solid",
				borderTopColor: primaryColor,
			},
		},
		logo: {
			width: "100%",
			maxW: 256,
			height: "auto",
			pt: 6,
			pb: 2,
		},
		popularArticlesHeading: {
			fontWeight: "light",
			pb: 4,
		},
	}),
};
