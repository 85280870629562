import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";
import { pulseScale } from "./animations";

export const NewArticleMarker: ComponentMultiStyleConfig = {
	parts: ["circleSvg", "span", "container"],
	baseStyle: {
		circleSvg: {
			width: "0.65em",
			height: "0.65em",
			mr: 2,
			animation: `${pulseScale} infinite alternate 1500ms ease-in-out`,
		},
		span: { textTransform: "uppercase" },
		container: {
			display: "flex",
			alignItems: "center",
			color: "base.red",
			...textStyles.newArticleMarker,
		},
	},
};
