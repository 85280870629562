import { StyleFunctionProps } from "@chakra-ui/theme-tools";

export const Breadcrumb = {
	baseStyle: ({ colorMode }: StyleFunctionProps) => ({
		container: {
			color: colorMode === "dark" ? "base.white" : "brand.darker",
			ml: -2,
		},
		separator: {
			mt: "-0.15em", // fix visual alignment, chosen visually
			mx: 1,
		},
	}),
};
