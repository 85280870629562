import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const ErrorPage: ComponentMultiStyleConfig = {
	parts: ["container", "title", "message"],
	baseStyle: {
		container: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			height: "50vh",
		},
		title: {
			textAlign: "center",
		},
		message: {
			textAlign: "center",
		},
	},
};
