import { headingStyles, textStyles } from "../textStyles";

export const Heading = {
	variants: {
		iconHeading: {
			"--icon-heading-oversize": "1.33em",
			"--icon-heading-spacing": "1rem",
			...textStyles.h5,
			display: "flex",
			flexFlow: "row",
			alignItems: "center",
			textTransform: "uppercase",
			letterSpacing: "0.02em",
			color: "base.mediumDarkGray",
			whiteSpace: "nowrap",
		},
	},
	sizes: {
		...headingStyles,
		iconHeading: {
			textTransform: "uppercase",
			letterSpacing: "0.02em",
			color: "base.mediumDarkGray",
			whiteSpace: "nowrap",
		},
	},
};
