import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const containerStyle = {
	width: "100%",
	margin: 0,
	maxWidth: "100vw ",
};
export const Toast: ComponentMultiStyleConfig = {
	parts: ["container", "content", "title", "description", "closeButton"],
	baseStyle: {
		container: {
			position: "relative",
			px: "container.inner.large",
		},
		content: {
			bg: "base.white",
			mb: 2,
			borderRadius: "md",
			bottom: -2,
			py: 3,
			px: 6,
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
	},
	variants: {
		success: {
			content: {
				bg: "base.success",
				color: "base.white",
			},
		},
	},
};
