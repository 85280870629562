import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const Blockquote: ComponentMultiStyleConfig = {
	parts: ["figure", "blockquote", "figcaption", "marks", "quoteWrapper"],
	baseStyle: {
		figure: {
			borderTop: "1px",
			borderColor: "base.gray",
			py: 7,
			display: "flex",
		},
		blockquote: {
			...textStyles.quote,
			width: "full",
		},
		marks: {
			minWidth: 12,
			width: 12,
			height: 12,
			mr: 18,
			mt: "0.5rem",
			color: "brand.primary",
		},
		figcaption: {
			ml: "auto",
		},
		quoteWrapper: {
			display: "flex",
			flexFlow: "row wrap",
		},
	},
};
