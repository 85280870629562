import { theme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

export const Input = {
	baseStyle: {
		field: {
			borderRadius: "base",
			py: 6,
			_hover: {
				boxShadow: "soft",
			},
		},
	},
	variants: {
		outline: {
			field: {
				borderColor: "transparent",
				_hover: {
					borderColor: "transparent",
				},
			},
		},
		flushed: (props: StyleFunctionProps) => {
			const flushedDefaults =
				theme.components.Input.variants?.flushed(props);

			return {
				...flushedDefaults,
				field: {
					...flushedDefaults?.field,
					borderColor: "base.darkGray",
					borderRadius: "none",
					_hover: {
						boxShadow: "none",
					},
				},
			};
		},
	},
};
