import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

const contentBox = {
	top: { lg: -16 },
	zIndex: 1,
	position: {
		lg: "relative",
	},
	paddingY: {
		base: 8,
		lg: 12,
	},
	paddingX: {
		base: 8,
		lg: 14,
	},
	marginLeft: {
		base: 0,
		lg: "17.5%", // chosen by fair dice roll
	},
	maxWidth: {
		base: "unset",
		lg: "82.5%",
	},
	_before: {
		content: { lg: '""' },
		zIndex: -1,
		position: "absolute",
		display: "block",
		top: 0,
		left: "100%",
		width: {
			base: 8,
			lg: 14,
		},
		height: "100%",
		backgroundColor: "inherit",
		backgroundImage: "inherit",
		pointerEvents: "none",
	},
};

export const BriefingSection: ComponentMultiStyleConfig = {
	parts: [
		"sectionWrapper",
		"top",
		"imageContainer",
		"title",
		"contentBox",
		"content",
		"divider",
	],
	baseStyle: {
		sectionWrapper: {
			marginTop: 20,
		},
		top: {
			display: {
				base: "block",
				lg: "flex",
			},
		},
		imageContainer: {
			flex: "1 0 25%",
			display: {
				base: "block",
				lg: "inline-block",
			},
			"& > figure > div": {
				height: {
					base: "100%",
					lg: "17.5rem",
				},
			},
		},
		title: {
			paddingY: 10,
			paddingX: 4,

			flex: "0 1 75%",
			alignSelf: "center",

			display: {
				base: "block",
				lg: "inline-block",
			},
			paddingLeft: {
				base: "unset",
				lg: 14,
			},
			marginTop: {
				base: "unset",
				lg: -10,
			},
		},
		content: {
			paddingRight: {
				base: "unset",
				lg: 20,
			},
		},
		contentBox: {
			...contentBox,
			...textStyles.briefingSection,
			backgroundColor: "base.lightGray",
		},
		newsletterBox: {
			...contentBox,
			paddingX: 0,
			paddingY: 0,
			paddingTop: {
				base: 8,
				lg: 16,
			},
		},
		divider: {
			marginTop: 9,
			marginBottom: 12,
		},
	},
};
