import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { boxedLayoutBorder } from "../mixins";

export const NotFoundPage: ComponentMultiStyleConfig = {
	parts: [
		"container",
		"backToContainer",
		"backToLink",
		"backToLogo",
		"backToHeading",
		"otherBrandsHeading",
		"otherBrandsList",
		"otherBrandsLink",
		"otherBrandsLogo",
	],
	baseStyle: {
		container: {
			...boxedLayoutBorder(),
			minH: "calc(100vh - 15rem)",
		},
		backToHeading: {
			mb: 6,
			textAlign: "center",
		},
		backToContainer: {
			py: { base: 12, lg: 24 },
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		backToLink: {
			textAlign: "center",
			textDecoration: "none",
			_hover: {
				textDecoration: "underline",
				color: "inherit",
			},
		},
		backToLogo: {
			mt: 6,
			height: 10,
			width: "auto",
		},
		otherBrandsHeading: {
			mt: 12,
			mb: 6,
			textAlign: "center",
		},
		otherBrandsList: {
			pb: { base: 12, lg: 24 },
			display: "grid",
			gridTemplateColumns: {
				base: "1fr",
				lg: "repeat(3, 1fr)",
			},
			gridGap: { base: 6, lg: 4 },
			mx: -2,
			listStyleType: "none",
			width: "100%",
		},
		otherBrandsLink: {
			display: "flex",
			alignContent: "center",
			flexDirection: "column",
			p: 2,
			textDecoration: "none",
			_hover: {
				textDecoration: "underline",
				color: "inherit",
			},
		},
		otherBrandsLogo: {
			height: 8,
			width: "auto",
			display: {
				base: "inline",
				lg: "block",
			},
			mb: 2,
		},
	},
};
