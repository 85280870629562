import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const KeyPoints: ComponentMultiStyleConfig = {
	parts: ["container", "title", "content"],
	baseStyle: {
		container: {
			display: "flex",
			bg: "base.keypointsBackground",
			py: 4,
			px: 6,
			flexDirection: {
				base: "column",
				lg: "row",
			},
			...textStyles.secondary,
		},
		title: {
			flex: "1 0 auto",
			paddingBottom: 3,
		},
		content: {
			flex: "2 1 auto",
			maxWidth: {
				base: "unset",
				lg: "66.66%",
			},
			ul: {
				listStyleType: "square",
				paddingLeft: {
					base: 5,
					lg: 0,
				},
				ml: 0,
			},
			li: {
				"&::marker": {
					color: "brand.primary",
				},
				_notLast: {
					mb: 2,
				},
			},
		},
	},
	variants: {
		test: {
			content: { border: "1px solid tomato" },
		},
		"two-columns": {
			container: {
				flexDirection: "column",
			},
			title: {
				fontWeight: "light",
				mb: 4,
			},
			content: {
				columns: { base: "auto", lg: 2 },
				columnGap: { base: "none", lg: "grid.gap" },
				maxWidth: "100%",
				ul: {
					pl: { base: 5, lg: 5 },
					ml: 0,
				},

				li: {
					// avoid a column break inside a li
					breakInside: "avoid-column",
				},
			},
		},
	},
};
