import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { pulseOpacity } from "./animations";

export const EditorTools: ComponentMultiStyleConfig = {
	parts: ["wrapper", "button"],

	baseStyle: {
		button: {
			borderRadius: 0,

			bg: "base.red",
			_focus: {
				boxShadow: "focusNoPadding",
			},
			"&[data-is-preview=true]": {
				bg: "base.error",
				animation: `${pulseOpacity} infinite alternate 1500ms ease-in-out`,
			},
			_last: {
				borderRadius: "0 0.25rem 0 0",
			},
		},
		wrapper: {
			position: "fixed",
			left: 0,
			bottom: 0,
			transform: "translate(calc(-100% + 2rem), 0)",
			transition: "transform 300ms ease-in-out",
			"&[data-is-open=true]": {
				transform: "translate(0, 0)",
			},
		},
	},
};
