import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const ArticleMetaData: ComponentMultiStyleConfig = {
	parts: [
		"desktopDivider",
		"mobileDivider",
		"datesWrapper",
		"dates",
		"datesContainer",
		"categoryList",
	],
	baseStyle: ({ desktopBreakpoint: bp }) => ({
		mobileDivider: {
			// we're hiding this for now
			display: { base: "none", [bp]: "none" },
			mt: 4,
		},
		desktopDivider: {
			display: { base: "none", [bp]: "block" },
			mt: 6,
			mb: 4,
		},
		datesWrapper: {
			display: "flex",
			justifyContent: "space-between",
			pb: { base: 4, [bp]: 0 },
			alignItems: "flex-start",
		},
		datesContainer: {
			display: { base: "flex", [bp]: "block" },
			justifyContent: "space-between",
			width: "100%",
			div: {
				_notLast: {
					pb: { base: 0, [bp]: 3 },
				},
			},
		},
		categoryList: {
			my: 7,
			_last: { mb: -3 },
		},
	}),
};
