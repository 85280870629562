import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const RiskNotice: ComponentMultiStyleConfig = {
	parts: ["container"],
	baseStyle: {
		container: {
			...textStyles.tertiary,
			fontWeight: "bold",
			color: "brand.darker",

			position: "relative",
			top: { base: -2, lg: 0 },
		},
	},
	variants: {
		desktop: {
			container: {
				mb: 5,
			},
		},
	},
};
