import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const ConsentPlaceholder: ComponentMultiStyleConfig = {
	parts: ["container", "paragraph", "title"],
	baseStyle: {
		title: {
			...textStyles.h3,
		},
		paragraph: {
			p: 4,
			textAlign: "center",
		},
		container: {
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			bg: "base.darkGray",
			color: "base.white",
		},
	},
};
