export const SkipLink = {
	baseStyle: {
		borderRadius: "0 0 0.25rem 0.25rem",
		textDecoration: "none",
		_focus: {
			p: 2,
			px: 4,
			top: "0 !important",
			bg: "brand.primary !important",
			color: "base.white",
			boxShadow: "none !important",
		},
	},
};
