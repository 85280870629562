import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const Search: ComponentMultiStyleConfig = {
	parts: [
		"container",
		"innerContainer",
		"inputContainer",
		"input",
		"submitButton",
		"toggle",
		"resultsContainer",
		"results",
		"resultsHeading",
		"resultsLink",
		"overlay",
	],
	baseStyle: {
		container: {
			position: "absolute",
			top: -1,
			right: 9, // size of search ( and menu button )
			left: "auto",
			width: "49rem",
			maxWidth: "50vw",
			p: 1,
			overflow: "hidden",
			pointerEvents: "none",
		},
		innerContainer: {
			overflow: { base: "visible", lg: "hidden" },
			transform: {
				base: "none",
				lg: "translate3d(calc(100% + var(--chakra-space-1)), 0, 0)", // add 1 to accommodate the padding from container not leaking the bg
			},
			transition: "transform 0.2s ease-in",
			clipPath: { base: "none", lg: "inset(-6px 0px -6px -6px)" }, // clip right the boxShadow
			bg: "base.white",
			pointerEvents: "none",
			".is-active &": {
				boxShadow: { base: "none", lg: "soft" },
				transform: "translate3d(0, 0, 0)",
				pointerEvents: "auto",
			},
		},
		inputContainer: {
			paddingBottom: { base: 2, lg: "0" },
			display: "flex",
			flexFlow: "row-reverse",
			alignItems: { base: "center", lg: "normal" },
			position: "relative",
			_before: {
				content: '""',
				zIndex: -1,
				position: "absolute",
				display: "block",
				left: "calc(50% - 50vw)",
				bottom: -0,
				width: "100vw",
				pointerEvents: "none",
				borderBottom: "1px solid",
				borderColor: "base.lightGray",
			},
		},
		input: {
			fontSize: "max(16px, 1em)", // set to min 16px to avoid ios zoom to the input
			minHeight: 12,
			py: 0,
			px: 2,
			borderRadius: "none",
			_hover: {
				bg: "base.lightGray",
			},
			_focus: {
				boxShadow: "focusSubtleInset",
			},
		},
		submitButton: {
			borderRadius: "none",
			minHeight: 12,
			_hover: {
				bg: "base.lightGray",
			},
			_focus: {
				borderWidth: 2,
				borderStyle: "solid",
				borderColor: "base.focus",
				bg: "base.lightGray",
			},
		},
		toggle: {
			borderRadius: 0,
			clipPath: "inset(-6px -6px -6px 0px)", // clip left the boxshadow
			minHeight: 12,
			".is-active &": {
				boxShadow: "soft",
				_focus: {
					borderWidth: 2,
					borderStyle: "solid",
					borderColor: "base.focus",
					bg: "base.lightGray",
				},
			},
			_focus: {
				clipPath: "none",
				boxShadow: "focusNoPadding",
			},
		},
		resultsContainer: {
			borderTopRadius: "none",
			borderBottomRadius: "md",
			p: 3,
		},
		results: {
			mb: 0,
			pb: 3,
			overflow: { base: "visible", lg: "hidden" },
		},
		resultsHeading: {
			...textStyles.h4,
			color: "base.gray",
			position: "relative",
			_before: {
				content: '""',
				zIndex: -1,
				position: "absolute",
				display: "block",
				left: "calc(50% - 50vw)",
				top: "-1px",
				width: "100vw",
				pointerEvents: "none",
				borderBottom: "1px solid",
				borderColor: "base.lightGray",
			},
			px: 3,
			pt: 4,
			pb: 2,
		},
		resultsLink: {
			_hover: {
				bg: "base.lightGray",
			},
			display: "block",
			p: 3,
			mx: 0,
			textDecoration: "none",
			_focus: {
				boxShadow: "focusSubtleInset",
			},
		},
		drawerContent: {
			mt: 12,
			"[data-scrolldir='up'] [data-has-top-banner='true'] &": {
				pt: 16,
			},
		},
		overlay: {
			bg: "base.black",
			position: "fixed",
			inset: 0,
			opacity: 0.5,
			zIndex: 1,
		},
	},
};
