import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { boxedLayoutBorder } from "../mixins";
import { textStyles } from "../textStyles";

export const OverviewPage: ComponentMultiStyleConfig = {
	parts: [
		"article",
		"wrapper",
		"loadMore",
		"type",
		"header",
		"heading",
		"resultsHeading",
		"resultsList",
		"breadcrumbs",
	],
	baseStyle: {
		wrapper: {
			py: { base: 7 },
			...boxedLayoutBorder(),
		},
		article: {
			width: "100%",
		},
		breadcrumbs: {
			pb: 10,
		},
		loadMore: {
			py: 16,
			display: "flex",
			justifyContent: "center",
		},
		type: {
			display: "block",
			fontSize: "sm",
			fontWeight: "bold",
			letterSpacing: "0.04em",
			color: "base.darkGray",
			textTransform: "uppercase",
			pb: 2,
		},
		header: {
			...textStyles.secondary,
			width: "100%",
			color: "base.darkerGray",
		},
		heading: {
			display: "flex",
			alignItems: "center",
			...textStyles.h2,
			color: "base.black",
			pb: 2,
		},
		resultsHeading: {
			...textStyles.h3,
			color: "base.darkGray",
			py: 6,
		},
		resultsList: {
			display: "block",
			listStyleType: "none",
		},
	},
};
