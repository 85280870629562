import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";

export const Accordion = {
	parts: [],
	baseStyle: {
		container: {
			borderColor: "base.gray",
		},
		button: {
			...textStyles.h5,
			fontWeight: "normal",
			display: "flex",
			justifyContent: "space-between",
			pl: 0,
			py: 6,
			_focus: {
				boxShadow: "none",
			},
			_focusVisible: {
				boxShadow: "outline",
			},
		},
		subline: {
			fontWeight: "normal",
			display: "block",
			mt: 4,
		},
		labelWithSubline: {
			textAlign: "left",
			display: "block",
			width: "full",
		},
		panel: {
			pl: 0,
		},
	},
	variants: {
		"with-subline": {
			button: {
				alignItems: "flex-start",
				span: {
					...textStyles.report,
					_first: {
						fontWeight: "bold",
					},
				},
			},
		},

		"with-checkbox": ({ theme }: StyleFunctionProps) => ({
			container: {
				bg: "base.lighterGray",
				borderRadius: "base",
				// hacky solution to define the border color with a theme value
				// borderColor property will not be accepted somehow
				border: `1px solid ${theme.colors.base.lightGray}`,
				_notLast: {
					mb: 3,
				},
			},
			button: {
				fontWeight: "600",
				py: 7,
				border: "none",
			},
			panel: {
				pl: 18,
				pr: 16,
				pb: 7,
			},
		}),
	},
};
