export const Checkbox = {
	baseStyle: {
		control: {
			borderColor: "base.darkGray",
			_checked: {
				bg: "brand.primary",
				borderColor: "brand.primary",
				_disabled: {
					bg: "base.darkGray",
					borderColor: "base.darkGray",
					color: "base.white",
				},
			},
			_disabled: {
				bg: "base.darkGray",
				borderColor: "base.darkGray",
				color: "base.lightGray",
			},
		},
		label: {
			transition: "color 0.2s ease-in",
			_hover: {
				color: "base.darkGray",
			},
			_disabled: {
				opacity: 1,
			},
		},
	},
};
