import { ComponentStyleConfig } from "@chakra-ui/react";

export const Keyword: ComponentStyleConfig = {
	variants: {
		blackTag: {
			fontSize: "0.875rem",
			px: 5,
			py: 2,
			color: "base.white",
			textTransform: "uppercase",
			bg: "base.black",
		},
	},
};
