import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles, headingStyles } from "../textStyles";

export const Arguments: ComponentMultiStyleConfig = {
	parts: ["container", "list", "item", "sectionTitle"],
	baseStyle: {
		container: {
			backgroundColor: "base.keypointsBackground",
			paddingTop: 8,
			paddingBottom: 2,
			marginBottom: 8,
		},
		list: {
			display: "grid",
			gridTemplateColumns: {
				base: "1fr",
				lg: "1fr 1fr",
			},
		},
		item: {
			display: "flex",
			borderBottomWidth: "1px",
			borderBottomColor: "base.gray",
			paddingLeft: 8,

			"&:nth-of-type(even)": {
				paddingLeft: {
					lg: 14,
				},
			},
			"&:nth-last-of-type(2)": {
				borderBottomWidth: {
					lg: "0px",
				},
			},
			"&:nth-last-of-type(1)": {
				borderBottomWidth: "0px",
			},

			paddingY: {
				base: 4,
				lg: 8,
			},
		},
		itemText: {
			flex: "1",
			paddingLeft: 9,
			paddingRight: 6,
		},
		itemTextWrapper: {
			p: {
				paddingTop: 3,
			},
		},
		itemTitle: {
			...headingStyles.h4,
			paddingTop: 1,
		},
		sectionTitle: {
			paddingLeft: 8,
			paddingBottom: {
				base: 12,
				lg: 14,
			},
			...textStyles.argumentsHeading,
		},
	},
};
