import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { fullBleed } from "../mixins";

export const Disclaimer: ComponentMultiStyleConfig = {
	parts: ["container", "title", "text", "divider"],
	baseStyle: {
		container: {
			bg: "base.lightGray",
			...fullBleed,
			paddingTop: 8,
		},
		title: {
			paddingBottom: 8,
		},
		text: {
			paddingBottom: 8,
		},
	},
	variants: {
		"lp-marketworld-de": {
			divider: {
				mt: -8,
			},
		},
	},
};
