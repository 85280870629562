import { ThemeOverride } from "@chakra-ui/react";

export const mjTheme: ThemeOverride = {
	colors: {
		brand: {
			primary: "#A03A3A",
			secondary: "#A03A3A",
			dark: "#A03A3A",
			darker: "#A03A3A",
			crossPromoBackground: "#FAF6F6",
		},
	},
	components: {
		Carousel: {
			baseStyle: () => {
				return {
					nav: {
						_focus: {
							bg: "#fff", // this is tested for in a  unit test, adjust if necessary
						},
					},
				};
			},
		},
		Header: {
			baseStyle: {
				logo: {
					top: "-1%", // this is tested for in a  unit test, adjust if necessary
				},
			},
		},
	},
};
