import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const LayeredBorderDivider: ComponentMultiStyleConfig = {
	parts: ["divider", "title", "miniBorder"],
	baseStyle: ({ theme }) => ({
		divider: {
			// Set display in individual brand themes
			display: "none",

			"[data-boxed-layout] &": {
				position: "relative",
				zIndex: 1,
				paddingBottom: 3.5,
				_before: {
					content: '""',
					zIndex: -1,
					position: "absolute",
					display: "block",
					top: 0,
					boxShadow: "0 0 0 1px var(--chakra-colors-base-gray) inset",
					height: "1px",
				},
			},

			"[data-boxed-layout=false] &": {
				_before: {
					width: "100%",
					maxWidth: `100%`,
					left: "0",
				},
			},

			"[data-boxed-layout=true] &": {
				_before: {
					width: `var(--hundred-vw)`,
					left: {
						base: "calc(50% - var(--fifty-vw))",
						xl: "calc(-1 * var(--chakra-space-grid-gap-large))",
					},
					maxW: {
						base: `100vw`,
						xl: "calc(var(--chakra-sizes-container-maxW) - 2px)",
					},
				},
			},
		},
		miniBorder: {
			width: { base: 32, lg: 48 },
			borderTop: "1px solid",
			borderColor: "base.black",
		},
		title: {
			paddingBottom: 14,
		},
	}),
};
