import { NextPageContext } from "next";
import { BrandSlug, siteConfig } from "../portalstack.config";

export const getBrandBySlug = (slug: BrandSlug) => {
	const brandBySlug = siteConfig.brands.find((b) => b.slug === slug);

	if (!brandBySlug) {
		throw new Error(`Brand "${slug}" is not configured`);
	}

	return brandBySlug;
};

export const defaultBrand = getBrandBySlug(siteConfig.defaultBrandSlug);

export const getLocaleByBrand = (brand: BrandSlug) =>
	getBrandBySlug(brand).locale;

export const getBaseUrlByBrandSlug = (
	brandSlug: string,
	overwriteTemplate?: string,
): string =>
	(overwriteTemplate ?? process.env.MMG_BRAND_URL_TEMPLATE ?? "").replace(
		"{brandSlug}",
		brandSlug,
	);

export const getBrandFromReq = (req: NextPageContext["req"]) => {
	const host = req?.headers.host;

	return siteConfig.brands.find((b) => host?.includes(b.hostSearchWord));
};

export const getBrandFromUrl = (url: string) => {
	return siteConfig.brands.find((b) => url.includes(b.slug));
};
