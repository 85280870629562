import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { Layout } from "../../brandComponents/shared/HomePage/helpers";
import { boxedLayoutBorder } from "../mixins";

type SectionTypeName = Layout["__typename"];
type Parts = SectionTypeName | "outerWrapper";

const parts: Array<Parts> = ["outerWrapper"];

const baseStyle = {
	outerWrapper: {
		paddingTop: { base: 0, lg: 12 },
		paddingBottom: { base: 12, lg: 24 },
		...boxedLayoutBorder(),
	},
};

export const HomeSections: ComponentMultiStyleConfig = {
	parts,
	baseStyle,
	variants: {
		marketbrief: {
			outerWrapper: {
				paddingTop: 0,
				...boxedLayoutBorder(),
			},
		},
	},
};
