/* eslint-disable @typescript-eslint/unbound-method */
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { fillParent } from "../mixins";
import { headingStyles } from "../textStyles";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers([
		"container",
		"imageContainer",
		"content",
		"heading",
		"kicker",
	]);

export const FadeHeroSection = defineMultiStyleConfig({
	baseStyle: definePartsStyle(({ desktopBreakpoint: bp }) => ({
		container: {
			position: "relative",
		},
		imageContainer: {
			height: { base: "60vh", [bp]: "auto" },
		},
		content: {
			...fillParent,
			background: `transparent linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%) 0% 0% no-repeat padding-box`,
			display: "flex",
			justifyContent: "center",
			alignItems: "flex-end",
			padding: { base: 8 },
		},
		heading: {
			...headingStyles.h1,
			lineHeight: { base: 1.2, [bp]: 1.4 },
			textAlign: { base: "left", [bp]: "center" },
			color: "white",
			maxW: { base: "none", lg: "75%" },
		},
		kicker: {
			...headingStyles["h4-light"],
			textTransform: "uppercase",
			textAlign: "inherit",
			display: "block",
			mb: 2,
		},
	})),
});
