import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { fullBleed } from "../mixins";
import { headingStyles } from "../textStyles";

export const HomePageHeader: ComponentMultiStyleConfig = {
	parts: ["container", "heading"],
	baseStyle: {
		container: {
			px: { lg: 10 },
			pt: { base: 20, lg: 16 },
			pb: 10,
			bgColor: "base.contentHeaderBackground",
			...fullBleed,
			// select the first sibling
			"& + *": {
				...fullBleed,
			},
			// select the first sibling of the first sibling
			"& + * + *": {
				...fullBleed,
			},
		},
		heading: {
			...headingStyles["h1-light"],
		},
	},
};
