import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { convertBpToMediaQuery } from "../../utils/misc";
import { boxedLayoutBorder } from "../mixins";
import { bannerHeight, desktopMenuHeight, mobileMenuHeight } from "./header";

export const Article: ComponentMultiStyleConfig = {
	parts: ["sidebars", "pageContainer", "wallLogo", "wallButton"],
	baseStyle: ({ theme }) => ({
		sidebars: {
			[convertBpToMediaQuery("lg", theme)]: {
				position: "sticky",
				py: 4,

				top: {
					base: mobileMenuHeight,
					lg: desktopMenuHeight,
				},
				"[data-has-top-banner=true] &": {
					top: {
						base: mobileMenuHeight + bannerHeight,
						lg: desktopMenuHeight + bannerHeight,
					},
				},
			},
		},
		pageContainer: {
			...boxedLayoutBorder(),
		},
		wallLogo: {
			width: "auto",
			height: 8,
		},
		wallButton: {
			display: "block",
			width: "100%",
		},
	}),
};
