import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { headingStyles } from "../textStyles";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers([
		"title",
		"item",
		"itemTitle",
		"itemContent",
		"list",
		"container",
	]);

export const Advantages = defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		container: { py: 8, px: 10 },
		title: {
			textTransform: "uppercase",
			pb: { base: 12, lg: 8 },
			...headingStyles.h5,
		},
		item: { display: "flex", alignItems: "center" },
		itemTitle: { ps: 4, ...headingStyles.h4 },
		itemContent: {
			ps: 8 + 4, // iconWidth + gapWidth
			pt: 2,
			gridColumn: "2",
		},
		list: {
			display: "grid",
			gridTemplateColumns: { lg: "repeat(3, 1fr)" },
			gridGap: "grid.gap",
			flexDirection: "row",
		},
	}),
	variants: {
		briefingSection: definePartsStyle({
			container: {
				px: 0,
				py: 0,
				background: "transparent !important",
				color: "black !important",
			},
			item: { color: "black !important" },
			title: { pb: 0, color: "black !important" },
			list: { m: 0 },
		}),
	},
});
