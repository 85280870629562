import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { fullBleed } from "../../mixins";

export const PopularArticles: ComponentMultiStyleConfig = {
	parts: ["container", "title"],
	baseStyle: {
		container: {
			bg: "base.lightGray",
			...fullBleed,
			pt: { base: 4, lg: 8 },
			pb: { base: 8, lg: 8 },
		},
		title: {
			mt: 0,
			mb: 4,
		},
	},
};
