import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const PrettyDate: ComponentMultiStyleConfig = {
	parts: ["container", "label"],
	baseStyle: ({ desktopBreakpoint: bp }) => ({
		container: {
			...textStyles.tertiary,
			color: "base.mediumDarkGray",
			whiteSpace: "nowrap",
			time: {
				display: "inline-block",
			},
		},
		label: {
			color: { base: "base.mediumDarkGray", [bp]: "base.black" },
			pr: 2,
		},
	}),
};
