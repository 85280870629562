/* eslint-disable import/max-dependencies */
import { ThemeOverride } from "@chakra-ui/react";
import { Accordion } from "./accordion";
import { ArticleMetaData } from "./articleMetaData";
import { Author } from "./author";
import { Blockquote } from "./blockquote";
import { Breadcrumb } from "./breadcrumb";
import { BurgerButton } from "./burgerButton";
import { Button } from "./buttons";
import { Carousel } from "./carousel";
import { Checkbox } from "./checkbox";
import { CompanyQuickInfo } from "./companyQuickInfo";
import { Container } from "./container";
import { ContentHeader } from "./contentHeader";
import { ContentTitle } from "./contentTitle";
import { CrossPromoSection } from "./crossPromoSection";
import { CurrentDate } from "./currentDate";
import { Divider } from "./divider";
import { Drawer } from "./drawer";
import { ErrorPage } from "./errorPage";
import { Footer } from "./footer";
import { Gallery } from "./gallery";
import { globalStyles as global } from "../globalStyles";
import { GridNewsletterForm } from "./gridNewsletterForm";
import { Header } from "./header";
import { Heading } from "./heading";
import { HighlightedSection } from "./highlightedSection";
import { HomeSections } from "./homeSections";
import { Input } from "./input";
import { Link } from "./link";
import { List } from "./list";
import { Modal } from "./modal";
import { NewArticleMarker } from "./newArticleMarker";
import { NewsletterForm } from "./newsletterForm";
import { NewsletterParts } from "./newsletterParts";
import { PrettyDate } from "./prettyDate";
import { SkipLink } from "./skipLink";
import { Socialmedia } from "./socialmedia";
import { Switch } from "./switch";
import { Teaser } from "./teaser";
import { Text } from "./text";
import { Textarea } from "./textarea";
import { textStyles } from "../textStyles";
import { KeyPoints } from "./keyPoints";
import { Disclaimer } from "./disclaimer";
import { Tag } from "./tag";
import { Toast } from "./toast";
import { NotFoundPage } from "./notFoundPage";
import { PopularArticles } from "./teaser/popularArticles";
import { Search } from "./search";
import { ConsentPlaceholder } from "./consentPlaceholder";
import { TradingViewWidget } from "./tradingViewWidget";
import { OverviewPage } from "./overviewPage";
import { CMSAvatar } from "./CMSAvatar";
import { EditorTools } from "./editorTools";
import { MaintenancePage } from "./MaintenancePage";
import { Embed } from "./embed";
import { Article } from "./article";
import { TwoByTwoTextOnlyTeasers } from "./twoByTwoTextOnlyTeasers";
import { CompanyWidget } from "./companyWidget";
import { Advantages } from "./advantages";
import { BriefingSection } from "./briefingSection";
import { HomePageHeader } from "./homePageHeader";
import { Report } from "./report";
import { Arguments } from "./arguments";
import { Assessment } from "./assessment";
import { CompanyOverlay } from "./companyOverlay";
import { ContentHeaderQuickInfo } from "./contentHeaderQuickInfo";
import { Keyword } from "./keyword";
import { LandingPage } from "./landingPage";
import { LandingPageContentSection } from "./landingPage/landingPageContentSection";
import { ExternalLinksSection } from "./externalLinksSection";
import { PressReleaseSection } from "./pressReleaseSection";
import { RiskNotice } from "./riskNotice";
import { Wall } from "./wall";
import { LayeredBorderDivider } from "./layeredBorderDivider";
import { FadeHeroSection } from "./fadeHeroSection";

const colors = {
	brand: {
		primary: "#105FFF",
		secondary: "#0D44B4",
		dark: "#0D44B4",
		darker: "#09245A",
		crossPromoBackground: "#E9E9E9",
	},

	base: {
		pageBg: "#fff",
		pageBgDark: "#000",
		contentBg: "#fff",
		contentBgDark: "#000",
		focus: "rgba(16, 95, 255, 0.5)",
		red: "#A03A3A",
		lightRed: "#D3A3A3",
		lighterRed: "rgba(211, 163, 163, 0.1)",
		mediumRed: "#CB2727",
		black: "#000",
		white: "#fff",
		lighterGray: "#FAFAFA",
		lightGray: "#F0F2F5",
		mediumLightGray: "#E9E9E9",
		gray: "#BABABA",
		lightDivider: "rgba(186, 186, 186, 0.2)",
		mediumDarkGray: "#696969",
		darkGray: "#7c7c7c",
		darkerGray: "#5D5D5D",
		contentHeaderBackground: "#F5F5F5",
		keypointsBackground: "#E5E8EE",
		advantagesBackground: "transparent",
		error: "#ff4545",
		green: "#3BA747",
		success: "#105FFF",
	},

	ui: {
		menuIndicator: "#105FFF",
	},
};

const sans = `"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const fonts = {
	body: sans,
	heading: sans,
	sans,
	// TODO: change this permanently
	serif: sans,
	formerSerif: `"IBM Plex Serif", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif`,
};

export const gap = "1.75rem";
export const smallGap = "1rem";
export const largeGap = "3.5rem";

export const theme: ThemeOverride = {
	breakpoints: {
		smmd: "39em",
		mdlg: "55em",
		lgxl: "71em",
	},
	config: {
		initialColorMode: "light",
	},
	colors,
	fonts,
	textStyles,
	styles: {
		global,
	},
	zIndices: {
		header: 1500,
		overHeader: 1600,
	},
	shadows: {
		outline: `0 0 0 0.5rem white, 0 0 0 0.75rem var(--chakra-colors-base-focus)`,
		focus: `0 0 0 0.5rem white, 0 0 0 0.75rem var(--chakra-colors-base-focus)`,
		gray: `0 0 0 0.5rem var(--chakra-colors-base-lightGray)`,
		focusNoPadding: `0 0 0 0.25rem var(--chakra-colors-base-focus)`,
		focusInset: `inset 0 0 0 0.25rem var(--chakra-colors-base-focus)`,
		focusSubtleInset: `inset 0 0 0 0.125rem var(--chakra-colors-base-focus)`,
		menuIndicator: `inset 0px -5px 0px 0px var(--chakra-colors-ui-menuIndicator)`,
		"menuIndicator.focus": `inset 0px -10px 0px 0px var(--chakra-colors-base-focus)`,
		"menuIndicator.focus.gray": `inset 0px -10px 0px 0px var(--chakra-colors-base-gray)`,
		"menuIndicator.mobile": `inset 10px 0 0px 0px var(--chakra-colors-ui-menuIndicator)`,
		"menuIndicator.mobileFocus": `inset 20px 0 0px 0px var(--chakra-colors-base-focus)`,
		"outline.red": `0 0 0 3px ${colors.base.lightRed}`,
		soft: "0px 0px 6px rgba(0, 0, 0, 0.16)",
		error: `0 0 0 1px ${colors.base.error}`,
	},
	sizes: {
		grid: {},
		container: {
			maxW: "87rem",
		},
		38: "9.5rem",
	},
	space: {
		grid: {
			"gap.small": smallGap,
			gap,
			"gap.large": largeGap,
		},
		container: {
			"inner.small": smallGap,
			"inner.large": gap,
		},
		11: "2.75rem",
		13: "3.25rem",
		18: "4.5rem",
		22: "5.5rem",
		26: "6.5rem",
		38: "9.5rem",
	},
	components: {
		Accordion,
		Arguments,
		ArticleMetaData,
		Author,
		CMSAvatar,
		Blockquote,
		Breadcrumb,
		BriefingSection,
		BurgerButton,
		Button,
		Carousel,
		Checkbox,
		CompanyOverlay,
		CompanyQuickInfo,
		CompanyWidget,
		Container,
		ContentHeader,
		ContentTitle,
		CrossPromoSection,
		CurrentDate,
		Disclaimer,
		Divider,
		Drawer,
		EditorTools,
		ErrorPage,
		FadeHeroSection,
		Footer,
		Gallery,
		GridNewsletterForm,
		Header,
		Heading,
		HighlightedSection,
		HomeSections,
		ExternalLinksSection,
		Input,
		LayeredBorderDivider,
		Link,
		List,
		Modal,
		NewArticleMarker,
		NewsletterForm,
		NewsletterParts,
		OverviewPage,
		PressReleaseSection,
		PrettyDate,
		Report,
		RiskNotice,
		SkipLink,
		Socialmedia,
		Switch,
		Teaser,
		Text,
		Textarea,
		TradingViewWidget,
		KeyPoints,
		Tag,
		Toast,
		NotFoundPage,
		PopularArticles,
		Search,
		ConsentPlaceholder,
		MaintenancePage,
		Embed,
		Article,
		TwoByTwoTextOnlyTeasers,
		Advantages,
		HomePageHeader,
		Assessment,
		ContentHeaderQuickInfo,
		Keyword,
		LandingPage,
		LandingPageContentSection,
		Wall,
	},
};
