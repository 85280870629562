import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { textStyles } from "../textStyles";

export const Modal = {
	baseStyle: ({ theme }: StyleFunctionProps) => ({
		body: {
			px: { base: 6, md: 12 },
		},
		header: {
			px: { base: 6, md: 12 },
			py: { base: 6, md: 12 },
			...textStyles.h4,
		},
		footer: {
			borderTop: "1px",
			borderColor: "base.gray",
			px: { base: 6, md: 12 },
			pb: { base: 4, md: 9 },
			pt: { base: 4, md: 7 },
		},
		closeButton: {
			top: { base: 5, md: 12 },
			insetEnd: { base: 5, md: 12 },
		},
		dialog: {
			my: { base: 0, md: 6 },
			maxHeight: {
				base: "100vh",
				md: `calc(100% - ${theme.space["32"]})`,
			},
		},
		overlay: {
			bg: "whiteAlpha.800",
		},
	}),
	defaultProps: {
		size: "5xl",
	},
	variants: {
		lightbox: {
			dialog: {
				boxShadow: "none",
				bg: "transparent",
				m: 8,
			},
			dialogContainer: {
				zIndex: "overHeader",
			},
			overlay: {
				bg: { base: "whiteAlpha.800" },
				zIndex: "header",
			},
			body: {
				backgroundSize: "contain",
			},
			closeButton: {
				color: "base.white",
				bg: "base.black",
				top: 2,
				insetEnd: 2,
				_focus: {
					color: "base.black",
					bg: "base.white",
				},
			},
		},
	},
};
