export const Divider = {
	defaultProps: {
		variant: "light",
	},
	baseStyle: {
		opacity: 1,
		borderColor: "base.gray",
	},
	variants: {
		light: {
			borderColor: "base.lightDivider",
			borderStyle: "solid",
		},
		iconHeading: {
			opacity: 0.2,
			borderColor: "currentColor",
			borderStyle: "solid",
		},
	},
};
