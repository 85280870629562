import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { boxedLayoutBorder } from "../mixins";
import { textStyles } from "../textStyles";

const bg = "base.contentHeaderBackground";

const breadCrumbAndTimeStyles = (bp: string) => {
	return {
		gridColumn: { base: "1", [bp]: "span 6" },

		pb: {
			base: "0.4em",
			[bp]: "0",
		},
	};
};

const lpLayout = (bp: string) => ({
	hero: {
		gridColumn: { base: "1", [bp]: "1 / -1" },
		minHeight: "auto",
	},

	companyQuickInfo: {
		gridColumn: "auto",
		py: { base: 0, lg: 10 },
		display: "flex",
		flexDirection: { base: "column", lg: "row" },
		justifyContent: "space-between",
	},
});

const noHeaderBg = {
	row: {
		"&:nth-of-type(1)": {
			bg: "transparent",
		},
		"&:nth-of-type(2)": {
			_before: {
				content: "none",
			},
		},
	},
};

export const ContentHeader: ComponentMultiStyleConfig = {
	parts: [
		"row",
		"rowWrapper",
		"title",
		"companyQuickInfo",
		"aside",
		"breadcrumbs",
		"hero",
		"imageCopyright",
		"social",
		"slogan",
		"riskNotice",
		"currentDate",
	],
	baseStyle: ({
		desktopBreakpoint: bp,
		isWithKeyPoints,
	}: StyleFunctionProps) => {
		return {
			rowWrapper: {
				...boxedLayoutBorder(),
			},
			row: {
				position: "relative",
				"& > .content-header-full-width-element": {
					...boxedLayoutBorder(),
					maxWidth: "container.maxW",
					mx: "auto",
				},
				"& .content-header-full-bleed-grid": {
					width: "100%",
					gridTemplateColumns: {
						base: "auto",
						lg: "repeat(12, 1fr)",
					},
					gridGap: { base: "grid.gap.small", lg: "grid.gap" },
				},
				"&:nth-of-type(1)": {
					bg,
				},
				"&:nth-of-type(1) > *:first-of-type": {
					py: { base: "grid.gap.small", [bp]: "grid.gap" },
				},
				"&:nth-of-type(2) ": {
					_before: {
						content: "''",
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						zIndex: -1,
						width: "100%",
						height: { base: "70%", [bp]: 14 },
						bg,
					},
					"& .content-header-full-bleed-grid": {
						gridTemplateRows: {
							base: "repeat(3, auto)",
							lg: "repeat(1, auto)",
						},
					},
				},
			},
			breadcrumbs: {
				borderBottom: { base: "1px solid", [bp]: "none" },
				borderColor: "base.gray",
				...breadCrumbAndTimeStyles(bp),
			},
			currentDate: {
				display: "flex",
				alignItems: "center",
				p: 0,
				pt: 8,
				gridColumn: "1/-1",
				color: "base.black",
				"&, *": {
					...textStyles.default,
				},
			},
			companyQuickInfo: {
				gridColumn: { base: "1", [bp]: "7 / span 6" },
				display: "flex",
				justifyContent: {
					base: "stretch",
					[bp]: "flex-end",
				},
			},
			aside: {
				gridRow: { base: 1, [bp]: "auto" },
				gridColumn: { base: "span 1", [bp]: "10 / span 3" },
				alignSelf: { base: "flex-start" },
				marginTop: { lg: 20 },
			},
			title: {
				gridColumn: {
					base: "1",
					lg: "1 / -1",
				},
			},
			hero: {
				minHeight: 24,
				// negative values only work if we set the grid-template-rows to a specific row count
				gridRow: { base: "-2 / span 1", [bp]: "1" },
				gridColumn: { base: "1", [bp]: "1 / span 9" }, //
			},
			imageCopyright: {
				...textStyles.fineprint,
				color: "base.darkGray",
				backgroundColor: isWithKeyPoints
					? "base.keypointsBackground"
					: "unset",
				paddingY: 2,
				paddingLeft: isWithKeyPoints ? 6 : 0,
			},
			social: {
				display: { base: "block", [bp]: "none" },
			},
			slogan: {
				gridColumn: { base: "1/-1", lg: "span 6" },
			},
			riskNotice: {
				gridColumn: { base: "1/-1", lg: "span 6" },
				textAlign: { base: "left", lg: "right" },
			},
		};
	},
	variants: {
		"lp-marketbrief-de": (props) => ({
			...lpLayout(props.desktopBreakpoint),
			...noHeaderBg,
		}),
		"article-marketbrief-de": (props) => ({
			...lpLayout(props.desktopBreakpoint),
			...noHeaderBg,
		}),
		"lp-markettimes-de": (props) => ({
			...lpLayout(props.desktopBreakpoint),
			...noHeaderBg,
			currentDate: {
				"&, *": {
					color: "base.white",
				},
			},
		}),
		"article-markettimes-de": (props) => ({
			...lpLayout(props.desktopBreakpoint),
			...noHeaderBg,
		}),

		"lp-marketworld-de": (props) => lpLayout(props.desktopBreakpoint),
	},
};
