import { textStyles } from "../textStyles";

export const Text = {
	baseStyle: {
		...textStyles.primary,
		hyphens: "auto",
		_notLast: {
			mb: 6,
		},
		code: {
			backgroundColor: "base.lightGray",
			px: 1,
			borderRadius: "base",
		},
		mark: {
			px: 1,
		},
	},
	variants: {
		"structured-text-content": {
			...textStyles.secondary,
		},
		"structured-text-disclaimer": {
			...textStyles.disclaimer,
		},
		"structured-text-report": {
			...textStyles.report,
		},
	},
};
