import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

export const desktopBreakpoint = "lg";
export const desktopMenuHeight = 24;
export const mobileMenuHeight = 12;
export const bannerHeight = 14;

export const Header: ComponentMultiStyleConfig = {
	parts: [
		"container",
		"desktopWrapper",
		"header",
		"inner",
		"linkList",
		"logo",
		"mobileWrapper",
		"logoWrapper",
		"mobileDrawer",
		"nav",
		"navItems",
		"navWrapper",
		"widgetsWrapper",
		"spacing",
	],
	baseStyle: ({ colorMode }: StyleFunctionProps) => {
		const wrapperBase = {
			display: "flex",
			justifyContent: "space-between",
			height: "100%",
			alignItems: "center",
			width: "100%",
		};

		const backgroundColor =
			colorMode === "dark" ? "base.black" : "base.white";

		return {
			navWrapper: {
				display: "flex",
				flexDirection: "column",
			},
			desktopWrapper: {
				...wrapperBase,
				alignItems: "flex-end",
				position: "relative",
				// TODO: think of a more sophisticated solution for too many nav items
				"&.is-landing-page": {
					overflow: "hidden",
				},
			},
			mobileWrapper: {
				...wrapperBase,
				alignItems: "center",
			},

			widgetsWrapper: {
				pt: 2,
				alignItems: "center",
				position: "absolute",
				top: 0,
				right: 0,
				height: 6,
				transition: "visibility 0.2s ease-in, opacity 0.2s ease-in",
				opacity: 1,
				visibility: "visible",
				"[data-scrolldir='down'] &": {
					opacity: 0,
					visibility: "hidden",
				},
			},
			navItems: {
				display: "flex",
				alignItems: "flex-end",
				position: "relative",
			},
			wrapper: {
				pointerEvents: "none",
				position: "fixed",
				zIndex: "header",
				width: "100%",
				top: 0,
			},
			header: {
				display: "flex",
				flexDir: {
					base: "column",
					[desktopBreakpoint]: "column-reverse",
				},
			},
			container: {
				pointerEvents: "auto",
				backgroundColor,
				position: "relative",
				borderBottom: "1px solid",
				borderBottomColor: "base.gray",
				zIndex: 2,
				height: {
					base: mobileMenuHeight,
					[desktopBreakpoint]: desktopMenuHeight,
				},
				transition: "height 0.2s ease-in-out",
				willChange: "height",
				"[data-scrolldir='down'] &": {
					height: mobileMenuHeight,
				},
			},
			inner: {
				mx: "auto",
				display: "flex",
				height: "100%",
			},
			logoWrapper: {
				height: "100%",
				display: "flex",
				alignItems: "center",
			},
			logo: {
				position: "relative",
				top: "9%", // chosen by rolling a D20, this is tested for in a  unit test, adjust if necessary
				width: "auto",
				height: { base: "50%", [desktopBreakpoint]: "100%" },
				maxWidth: 64,
				transition: "height 0.35s ease-in-out",
				// this hack prevents a rendering bug in safari:
				// https://stackoverflow.com/questions/9983520/webkit-animation-is-leaving-junk-pixels-behind-on-the-screen/17822836#comment25905955_9983520
				padding: "1px",
				willChange: "height",
				"[data-scrolldir='down'] &": {
					height: "50%",
				},
			},
			mobileDrawer: {
				mt: 12,
				height: "calc(100vh - var(--chakra-space-12))",
				"[data-scrolldir='up'] [data-has-top-banner='true'] &": {
					pt: 16,
				},
			},
			spacing: {
				height: {
					base: mobileMenuHeight,
					lg: desktopMenuHeight,
				},
				"[data-has-top-banner=true] &": {
					height: {
						base: mobileMenuHeight + bannerHeight,
						lg: desktopMenuHeight + bannerHeight,
					},
				},
			},
			nav: {
				height: "100%",
				display: "flex",
				alignItems: "flex-end",
			},
			linkList: {
				width: "100%",
				display: "flex",
				flexDirection: { base: "column", [desktopBreakpoint]: "row" },
				mb: 0,
				li: {
					position: "relative",
					_notLast: {
						mr: { base: 0, [desktopBreakpoint]: 6 },
						_after: {
							display: {
								base: "block",
								[desktopBreakpoint]: "none",
							},
							content: "''",
							width: "100%",
							position: "absolute",
							left: 0,
							bottom: "-1px",
							borderBottom: "1px solid",
							borderColor: "base.lightDivider",

							"footer &": {
								display: "none",
							},
						},
					},
				},
			},
			topBanner: {
				pointerEvents: "auto",
				display: "flex",
				height: bannerHeight,
				textAlign: "center",
				lineHeight: 1,
				alignItems: "center",
				justifyContent: "center",
				paddingX: 4,
				paddingY: 2,
				willChange: "transform",
				zIndex: 1,
				textDecoration: "none",
				_hover: {
					textDecoration: "underline",
				},
				_focus: {
					boxShadow: "none",
					bg: "brand.darker",
					color: "base.white",
				},
				"[data-scrolldir='down'] &": {
					transform: "translateY(-100%)",
					pointerEvents: "none",
				},
			},
		};
	},
};
