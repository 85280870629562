import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { widgetBorderStyle } from "../mixins";
import { textStyles } from "../textStyles";

// Chakra units drive me crazy, so I will use rem again… sue me.
const linkLogoWSmall = "1.5rem";
const linkLogoH = "2rem";
const linkLogoWBig = "7rem";

export const CompanyWidget: ComponentMultiStyleConfig = {
	parts: [
		"widget",
		"header",
		"headerLink",
		"logo",
		"link",
		"divider",
		"infoList",
		"infoRowRight",
		"infoRowLeft",
		"infoInnerRow",
		"infoCopyButton",
		"link",
		"linkLogo",
	],
	baseStyle: ({ desktopBreakpoint: bp }: StyleFunctionProps) => ({
		widget: {
			...widgetBorderStyle,
			paddingX: { base: 2, [bp]: 4 },
			paddingTop: { base: 2, [bp]: 6 },
		},
		header: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			paddingBottom: 4,
		},
		headerLink: {
			display: "flex",
			flex: "1",
			alignItems: "center",
		},
		logo: {
			minW: 7,
			maxW: 12,
			height: "auto",
			width: "100%",
			marginRight: 4,
			minHeight: "2em",
			display: "flex",
			alignItems: "center",
		},
		divider: {},
		infoList: {
			...textStyles.companyWidget,
			paddingY: 4,
		},
		infoRow: {
			paddingY: 2,
			display: "flex",
			justifyContent: "space-between",
			"&[data-divider-wrapper]": {
				"& + &": {
					display: "none",
				},
			},
		},

		infoRowRight: {
			textAlign: "right",
			whiteSpace: "pre",
		},
		infoRowLeft: {
			whiteSpace: "nowrap",
		},
		infoCopyButton: {
			marginLeft: 3,
		},
		infoInnerRow: {
			pb: "0.66em",
		},
		link: {
			display: "block",
			lineHeight: 1,
		},
		linkLogo: {
			display: "block",
			h: linkLogoH,
			w: linkLogoWBig,

			img: {
				objectPosition: "right",
			},
		},
		bottom: {},
		bottomLink: {
			minW: linkLogoWSmall,
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			...widgetBorderStyle,
			mt: 2,
			p: 4,
		},
		bottomLinkLogo: {
			w: linkLogoWSmall,
			mr: 3,
		},
	}),
	variants: {
		overlay: {
			widget: {
				border: "none",
				paddingX: 0,
			},
		},
	},
};
