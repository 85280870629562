import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const Report: ComponentMultiStyleConfig = {
	parts: ["container", "title", "text", "companyQuickInfo", "wrap"],
	baseStyle: {
		button: {
			color: "red",
		},
		container: {
			paddingY: 8,
			marginTop: 8,
		},
		title: {
			paddingBottom: 8,
		},
		text: {
			paddingBottom: 8,
			...textStyles.report,
		},
		companyQuickInfo: {
			pb: 16,
		},
	},
};
