/* !
 * hamburger adapted from:
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

import { CSSObject } from "@chakra-ui/styled-system";
import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { px2rem } from "../../utils/misc";

const config: {
	width: number;
	height: number;
	spacing: CSSObject["bottom"];
	padding: number;
	strokeWidth: string;
	color: (colorMode: string) => string;
} = {
	width: 5,
	height: 4,
	spacing: px2rem(6),
	padding: 4,
	strokeWidth: "1px",
	color: (colorMode) => (colorMode === "dark" ? "base.white" : "base.black"),
};

const hamburgerLine: (mode: string) => CSSObject = (colorMode) => ({
	display: "block",
	width: config.width,
	height: config.strokeWidth,
	backgroundColor: config.color(colorMode),
	position: "absolute",
});

export const BurgerButton: ComponentMultiStyleConfig = {
	parts: ["hamburger", "hamburgerInner", "hamburgerBox"],
	baseStyle: ({ colorMode }: StyleFunctionProps) => ({
		hamburger: {
			minWidth: 10,
			minHeight: 10,
			display: "inline-flex",
			justifyContent: "center",
			alignItems: "center",
			transitionProperty: "opacity, filter",
			transitionDuration: "0.15s",
			transitionTimingFunction: "linear",
			font: "inherit",
			color: "inherit",
			textTransform: "none",
			backgroundColor: "transparent",
			border: 0,
			margin: 0,
			overflow: "visible",
			_hover: {
				backgroundColor:
					colorMode === "dark" ? "whiteAlpha.200" : "gray.100",
			},
			_focus: {
				borderRadius: 0,
				outline: "none",
				backgroundColor: "none",
			},
			_focusVisible: {
				boxShadow: "outline",
			},
		},
		hamburgerInner: {
			...hamburgerLine(colorMode),
			top: "50%",
			marginTop: `-${config.strokeWidth})`,
			transitionProperty: "transform",
			transitionDuration: "0.075s",
			transitionTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
			_before: {
				...hamburgerLine(colorMode),
				content: "''",
				top: `-${config.spacing}`,
				transition: "top 0.075s 0.12s ease, opacity 0.075s ease",
			},
			_after: {
				...hamburgerLine(colorMode),
				content: "''",
				bottom: `-${config.spacing}`,
				transition:
					"bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)",
			},

			".is-active &": {
				backgroundColor: config.color(colorMode),
				transform: "rotate(45deg)",
				transitionDelay: "0.12s",
				transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
				_before: {
					backgroundColor: config.color(colorMode),
					top: 0,
					opacity: 0,
					transition: "top 0.075s ease, opacity 0.075s 0.12s ease",
				},
				_after: {
					backgroundColor: config.color(colorMode),
					bottom: 0,
					transform: "rotate(-90deg)",
					transition:
						" bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)",
				},
			},
		},
		hamburgerBox: {
			width: config.width,
			height: config.height,
			display: "inline-block",
			position: "relative",
		},
	}),
};
