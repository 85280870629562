import { widgetBorderStyle } from "../mixins";

export const Textarea = {
	baseStyle: {
		py: 3,
		...widgetBorderStyle,
	},
	variants: {
		outline: {
			_hover: {
				borderColor: "base.darkGray",
			},
		},
	},
};
