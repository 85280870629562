import { theme } from "@chakra-ui/react";
import { MultiStyleOverride } from "./types";

export const Drawer: MultiStyleOverride<typeof theme.components.Drawer> = {
	variants: {
		"mobile-menu": {
			footer: {
				px: 0,
				mx: 6,
				borderTop: "1px",
				borderColor: "base.gray",
				alignItems: "flex-start",
				justifyContent: "flex-start",
			},
		},
	},
};
