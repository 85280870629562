import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { widgetBorderStyle } from "../mixins";

export const TradingViewWidget: ComponentMultiStyleConfig = {
	parts: ["container", "inner"],

	baseStyle: ({ colorMode, desktopBreakpoint }: StyleFunctionProps) => {
		return {
			container: {
				display: "flex",
				flexFlow: "column nowrap",
				...widgetBorderStyle,
			},
			inner: {
				width: "100%",
				height: "100%",
				display: "flex",
				flexFlow: "column nowrap",
				"> div": {
					height: "100%",
					"> div": {
						height: "100%",
					},
				},
			},
		};
	},
};
