import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const Socialmedia: ComponentMultiStyleConfig = {
	parts: [
		"desktopSpacing",
		"desktopWrapper",
		"flexList",
		"flexListDesktop",
		"flexListMobile",
		"mobileWrapper",
		"shareHeader",
		"shareHeaderDesktop",
		"socialIcon",
		"socialText",
		"socialWrapperDesktop",
	],
	baseStyle: {
		desktopSpacing: {
			py: 6,
		},
		desktopWrapper: {
			borderTop: "1px",
			borderColor: "base.gray",
			pb: 9,
		},
		flexList: {
			display: "flex",
			flex: "1 1 50%",
			listStyle: "none",
		},
		flexListDesktop: {
			flexDirection: "column",
			justifyContent: "space-evenly",
		},
		flexListMobile: {
			flexDirection: "row",
			justifyContent: "end",
			gap: "30px",
		},
		mobileWrapper: {
			py: 3,
			alignItems: "center",
		},
		shareHeader: {
			color: "base.darkerGray",
			textTransform: "uppercase",
		},
		shareHeaderDesktop: {
			paddingTop: 5,
			paddingBottom: 9,
		},
		link: {
			display: "block",
			width: "100%",
			textAlign: "left",
			_focus: {
				boxShadow: "focus",
				outline: "none",
			},
		},
		socialIcon: {
			width: "1.5rem",
			height: "1.5rem",
			color: "base.red",
		},
		socialText: {
			display: "inline-block",
			verticalAlign: "middle",
			paddingLeft: 4,
		},
		socialWrapperDesktop: {
			paddingY: 2,
		},
	},
};
